import React from 'react';
import { withTranslation } from 'react-i18next'; // 国际化
import { withRouter } from 'react-router-dom';
import { Row, Form, Button, Input, message, Space } from 'antd';
import { connect } from 'react-redux';
import LangSelect from '@/components/LangSelect'; // 切换语言
import beforeLogin from '@/services/beforeLogin.js';
import { encrypt } from '@/constants/common';

@withTranslation(['leftMenu', 'beforeLogin'])

// 重置密码页面
class NewResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.resetPasswordForm = React.createRef(); // 忘记密码表单
    this.state = {
      username: localStorage.getItem('username')
    };
  }
  // 获取URL参数
  getQueryVariable = variable => {
    let queryString = this.props.location.search.substring(1);
    let queryArr = queryString.split('&');
    for (let i = 0; i < queryArr.length; i++) {
      var paraArr = queryArr[i].split('=');
      if (paraArr[0] === variable) {
        return paraArr[1];
      }
    }
    return false;
  };

  // 重置密码
  resetPassword = values => {
    const { t } = this.props;
    for (let key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    values.password = encrypt(
      values.password && values.password.trim(),
      this.state.username && this.state.username.trim()
    );
    values.confirmPassword = encrypt(
      values.confirmPassword && values.confirmPassword.trim(),
      this.state.username && this.state.username.trim()
    );
    // delete values.confirmPwd;
    let userIdString = this.getQueryVariable('userId');
    let hour = this.getQueryVariable('hour');
    if (userIdString && hour) {
      let params = {
        ...values,
        userId: userIdString,
        hour: hour,
        username: this.state.username
      };
      beforeLogin
        .resetPwd(params)
        .then(res => {
          if (res.code === 200) {
            message.success(t('beforeLogin:resetPassword.resetPwdSuccess'));
            window.location.href = '/login'; // 跳转至登录页面
          } else {
            this.props.history.push({
              pathname: '/forgetPassword'
            });
          }
        })
        .catch(error => {});
    } else {
      message.success(t('beforeLogin:resetPassword.userIdIsNull'));
    }
  };
  // 取消重置密码
  changeRoute = () => {
    this.props.history.push('/login');
  };
  // 组件挂载前
  UNSAFE_componentWillMount() {
    document.getElementsByTagName('body')[0].style.backgroundColor =
      'rgb(16, 34, 54)';
  }
  goBackButton = () => {
    window.location.href = '/login';
  };

  render() {
    let { t } = this.props;
    return (
      <div className="curtomer-style-register register_mainBox">
        <div className="forget_main">
          {/*公司logo */}
          <div className="logo flex">
            <span className="logo-content">
              <img alt="logo" src="/static/images/alpha-logo.png" />
            </span>
            <div className="switch-language">
              <LangSelect />
            </div>
          </div>
          <div className="forget_form_body">
            <div className="register_login">
              <span className="register_text_Account">
                {t('beforeLogin:forgetPassword.AlreadyHaveAnAccount')}{' '}
              </span>
              <a className="register_text_login" href="/#/Login">
                {t('beforeLogin:forgetPassword.LogInNow')}
              </a>
            </div>
            <div className="register_bg">
              {/* 忘记密码 */}
              <h1 className="register_title">
                {t('beforeLogin:forgetPassword.forgetPassword')}
              </h1>
              <Form
                className="forget_form"
                ref={this.resetPasswordForm}
                id="normal_login"
                layout="vertical"
                onFinish={this.resetPassword}
                initialValues={{
                  password: '',
                  confirmPassword: ''
                }}
              >
                {/* 密码 */}
                <Row gutter={8}>
                  <Form.Item
                    label={t('registerPage:registerPage.password')}
                    name="password"
                    validateTrigger="onBlur"
                    className="forget_hundred"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'registerPage:registerPage.passwordIsRequired'
                        )
                      },

                      {
                        validator: (rule, value) => {
                          if (typeof value === 'string') {
                            value = value.trim();
                            if (value.length === 0) {
                              return Promise.reject(
                                t(
                                  'registerPage:registerPage.passwordNotFullNull'
                                )
                              );
                            }
                          }

                          if (value.length >= 4 && value.length <= 20) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              t('registerPage:registerPage.passwordLimit')
                            );
                          }
                        }
                      }
                    ]}
                  >
                    <Input type="password" maxLength={20} />
                  </Form.Item>
                </Row>
                {/* 确认密码 */}
                <Row gutter={8}>
                  <Form.Item
                    label={t('registerPage:registerPage.confirmPwd')}
                    name="confirmPassword"
                    dependencies={['password']}
                    validateTrigger="onBlur"
                    className="forget_hundred"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'registerPage:registerPage.confirmPwdIsRequired'
                        )
                      },

                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (typeof value === 'string') {
                            value = value.trim();
                          }
                          if (
                            !value ||
                            getFieldValue('password').trim() === value.trim()
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            t(
                              'registerPage:registerPage.confirmPwdisInconsistent'
                            )
                          );
                        }
                      })
                    ]}
                  >
                    <Input type="password" maxLength={20} />
                  </Form.Item>
                </Row>

                {/* 注册和取消按钮 */}
                <Row gutter={8} justify="center">
                  <Space>
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      className="btn-radius_25 btn_white"
                    >
                      {t('beforeLogin:forgetPassword.submit')}
                    </Button>
                    <Button
                      shape="round"
                      className="btn-radius_25"
                      onClick={this.goBackButton}
                    >
                      {t('common:common.login')}
                    </Button>
                  </Space>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.user.name
  };
};
export default withRouter(connect(mapStateToProps)(NewResetPassword));
