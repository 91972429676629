import { app } from '@/constants/actionType';
import { setLeftMenuList } from '@/utils/token';

//reducer
function initLangage() {
  let lang =
    localStorage.getItem('language') ||
    navigator.language ||
    navigator.userLanguage; // 获取浏览器的语言环境，兼容IE的写法
  if (lang) {
    lang = lang.substr(0, 2).toLowerCase(); // 截取前两位字符，并转化为小写
    return lang;
  } else {
    return 'en';
  }
}

export default function (state, action) {
  if (!state) {
    state = {
      spinLoading: false,
      toPath: '/',
      currentModule: '', //当前模块
      accessMenu: [], //可访问的菜单,
      openAccessMenu: [], //展开的可访问的菜单(子级包含父级name)
      moduleList: [], //模块列表
      moduleMenu: [], //模块菜单
      language: initLangage(),
      sysSn: '',
      depotId: localStorage.getItem('depotId'),
      isRefresh: false,
      leftMenuList: '',
      allSystem: [],
      sysRole: [localStorage.getItem('sysRole')] || [],
      projectName: localStorage.getItem('projectName'),
      stationType: localStorage.getItem('stationType') || 0, // 0->工商业  1->BMS
      theme: localStorage.getItem('theme') || 'dark',
      dataType: localStorage.getItem('dataType') || '',
      blmuCount: localStorage.getItem('BlmuCount') || '',
      bcmuCount: localStorage.getItem('BcmuCount') || '',
      blmuInfo: localStorage.getItem('BlmuInfo') || {}
    };
  }
  switch (action.type) {
    case app.SPIN_LOADING:
      return { ...state, spinLoading: action.spinLoading };
    case app.UPDATE_TOPATH:
      return { ...state, toPath: action.toPath };
    case app.UPDATE_ACCESSMENU:
      try {
        setLeftMenuList(JSON.stringify(action.moduleList));
      } catch (error) {}

      return {
        ...state,
        currentModule: action.currentModule,
        accessMenu: action.accessMenu,
        openAccessMenu: action.openAccessMenu,
        moduleMenu: action.moduleMenu,
        moduleList: action.moduleList
      };
    case app.UPDATE_MODULE:
      return {
        ...state,
        currentModule: action.currentModule,
        moduleMenu: action.moduleMenu
      };
    case app.UPDATE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    case app.UPDATE_SYS_SN:
      return {
        ...state,
        sysSn: action.sysSn
      };
    case app.UPDATE_DEPOT_ID:
      return {
        ...state,
        depotId: action.depotId
      };
    case app.UPDATE_SYS_LIST:
      return {
        ...state,
        isRefresh: action.isRefresh
      };
    case app.UPDATE_SYS_ROLE:
      return {
        ...state,
        sysRole: action.sysRole
      };

    case app.SET_PROJECT_NAME:
      return {
        ...state,
        projectName: action.projectName
      };
    case app.UPDATE_THEME:
      return {
        ...state,
        theme: action.theme
      };
    case app.DATA_TYPE:
      return {
        ...state,
        dataType: action.dataType
      };
    case app.UPDATE_STATION_TYPE:
      return {
        ...state,
        stationType: action.stationType
      };
    case app.UPDATE_SHOW_CHANGE_STATION:
      return {
        ...state,
        showChangeStation: action.showChangeStation
      };
    case app.UPDATE_BLMUCOUNT:
      return {
        ...state,
        blmuCount: action.blmuCount
      };
    case app.UPDATE_BCMUCOUNT:
      return {
        ...state,
        dataType: action.bcmuCount
      };
    case app.UPDATE_BLMUINFO:
      return {
        ...state,
        dataType: action.BlmuInfo
      };
    default:
      return state;
  }
}
