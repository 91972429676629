/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:11:41
 */
import zh_changePwd_Message from './zh-CN/translation.json';
import en_changePwd_Message from './en-US/translation.json';
import de_changePwd_Message from './de-DE/translation.json';

export const langPwdMessage = {
  zh_changePwd_Message,
  en_changePwd_Message,
  de_changePwd_Message
};
