/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:24:07
 * @LastEditTime: 2023/12/25 19:14:24
 */
import en_productSuggestions from './en-US/translation.json';
import zh_productSuggestions from './zh-CN/translation.json';
import de_productSuggestions from './de-DE/translation.json';

export const productSuggestions = {
  en_productSuggestions,
  zh_productSuggestions,
  de_productSuggestions
};
