/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:13:10
 */
import en_history from './en-US/translation.json';
import zh_history from './zh-CN/translation.json';
import de_history from './de-DE/translation.json';

export const langHistory = { en_history, zh_history, de_history };
