/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:15:17
 */
import en_update from './en-US/translation.json';
import zh_update from './zh-CN/translation.json';
import de_update from './de-DE/translation.json';

export const langUpdate = { en_update, zh_update, de_update };
