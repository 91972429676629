import React from 'react';
import { withTranslation } from 'react-i18next'; // 国际化
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Form, Button, Input, Select, message } from 'antd';
import { connect } from 'react-redux';
import LangSelect from '@/components/LangSelect'; // 切换语言
import variables from '@/style/styles/variables.scss';
import beforeLogin from '@/services/beforeLogin.js';

const { Option } = Select;
const { Header } = Layout;

@withTranslation(['leftMenu', 'beforeLogin'])

// 注册页面对应的组件
class ForgetUserName extends React.PureComponent {
  constructor(props) {
    super(props);
    this.forgetUserNameForm = React.createRef(); // 忘记用户名页面
    this.state = {};
  }
  // 找回用户名
  submitForgetUserName = values => {
    const { t } = this.props;
    for (let key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    beforeLogin
      .forgetUsername(values)
      .then(res => {
        if (res.code === 200) {
          message.success(t('beforeLogin:forgetUserName.userEmailSend'));
          this.changeRoute();
        }
      })
      .catch(error => {});
  };
  // 取消找回用户名
  changeRoute = () => {
    this.props.history.push('/login');
  };
  // 组件挂载前
  UNSAFE_componentWillMount() {
    document.getElementsByTagName('body')[0].style.backgroundColor =
      'rgb(16, 34, 54)';
  }
  render() {
    let { t } = this.props;
    return (
      <div
        className="curtomer-style"
        style={{ background: 'rgb(16, 34, 54)', height: '100%' }}
      >
        <Header
          style={{
            background: variables.menuBg,
            padding: 0,
            height: 'auto',
            width: '100%'
          }}
        >
          <Row>
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            <Col
              xs={16}
              sm={16}
              md={16}
              lg={16}
              xl={16}
              style={{ textAlign: 'center', color: '#ccc', fontSize: '20px' }}
            >
              {t('leftMenu.headerTitle')}
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={4}>
              <LangSelect />
            </Col>
          </Row>
        </Header>

        <div
          style={{
            border: '1px solid rgb(12,69,85)',
            padding: '0.2rem',
            width: '40%',
            margin: '0.2rem auto'
          }}
        >
          {/* 忘记用户名 */}
          <h1
            style={{
              textAlign: 'center',
              margin: '0.1rem auto',
              fontSize: '0.5rem',
              color: '#ccc'
            }}
          >
            {t('beforeLogin:forgetUserName.forgetUserName')}
          </h1>
          <Form
            ref={this.forgetUserNameForm}
            id="normal_login"
            layout="vertical"
            onFinish={this.submitForgetUserName}
            initialValues={{ email: '', snOrLicense: '', userType: 1 }}
          >
            <Row gutter={8}>
              {/* 邮箱 */}
              <Form.Item
                label={t('beforeLogin:forgetUserName.email')}
                name="email"
                style={{ width: '100%' }}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('beforeLogin:forgetUserName.emailIsRequired')
                  },
                  {
                    pattern:
                      /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z0-9_\-\+]{2,8})$/,
                    message: t('beforeLogin:forgetUserName.emailLimit')
                  }
                ]}
              >
                <Input maxLength={64} />
              </Form.Item>
            </Row>
            <Row gutter={8}>
              {/* 用户类型 */}
              <Form.Item
                label={t('beforeLogin:forgetUserName.userType')}
                name="userType"
                style={{ width: '100%' }}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('beforeLogin:forgetUserName.userTypeRequired')
                  }
                ]}
              >
                <Select>
                  <Option key={1} value={1}>
                    终端用户
                  </Option>
                  <Option key={2} value={2}>
                    安装商用户
                  </Option>
                </Select>
              </Form.Item>
            </Row>
            <Row gutter={8}>
              {/* snOrLicense */}
              <Form.Item
                label={t('beforeLogin:forgetUserName.snOrLicense')}
                name="snOrLicense"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t(
                      'beforeLogin:forgetUserName.snOrLicenseIsRequired'
                    )
                  }
                ]}
                style={{ width: '100%' }}
              >
                <Input maxLength={64} />
              </Form.Item>
            </Row>

            {/* 注册和取消按钮 */}
            <Row gutter={8}>
              <Form.Item style={{ margin: '4px auto' }}>
                <Button type="primary" htmlType="submit">
                  {t('beforeLogin:forgetUserName.submit')}
                </Button>
                <Button
                  htmlType="button"
                  style={{ marginLeft: '5px' }}
                  onClick={this.changeRoute}
                >
                  {t('beforeLogin:forgetUserName.cancel')}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.user.name
  };
};
export default withRouter(connect(mapStateToProps)(ForgetUserName));
