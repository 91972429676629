/*
 * @Description:
 * @Author: Wayne Hu
 * @Date: 2023/01/18 17:53:43
 * @LastEditTime: 2024/07/09 14:30:21
 */
import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateLanguage } from '@/actions/app';
import { languageList } from '@/constants/language';
const { Option } = Select;
const LangSelect = props => {
  let { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.language);
    props.updateLanguage({ language: props.language });
  }, []);
  return (
    <Select
      defaultValue={props.language}
      onChange={value => {
        i18n.changeLanguage(value);
        props.updateLanguage({ language: value });
      }}
      style={{ margin: '0px 0.3rem', width: '1.2rem' }}
      size="small"
    >
      {languageList
        .slice(0, props.dataType === 'local' ? 2 : languageList.length)
        .map(item => (
          <Option key={item.code} value={item.code}>
            {item.label}
          </Option>
        ))}
    </Select>
  );
};
const mapStateToProps = state => {
  return {
    language: state.app.language,
    dataType: state.app.dataType
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: lang => {
      dispatch(updateLanguage(lang));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LangSelect);
