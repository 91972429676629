export const timeZoneList = [
  {
    StandardName: 'Dateline Standard Time',
    DisplayName: '(UTC-12:00) International Date Line West'
  },
  {
    StandardName: 'UTC-11',
    DisplayName: '(UTC-11:00) Coordinated Universal Time-11'
  },
  {
    StandardName: 'Aleutian Standard Time',
    DisplayName: '(UTC-10:00) Aleutian Islands'
  },
  {
    StandardName: 'Hawaiian Standard Time',
    DisplayName: '(UTC-10:00) Hawaii'
  },
  {
    StandardName: 'Marquesas Standard Time',
    DisplayName: '(UTC-09:30) Marquesas Islands'
  },
  {
    StandardName: 'Alaskan Standard Time',
    DisplayName: '(UTC-09:00) Alaska'
  },
  {
    StandardName: 'UTC-09',
    DisplayName: '(UTC-09:00) Coordinated Universal Time-09'
  },
  {
    StandardName: 'Pacific Standard Time (Mexico)',
    DisplayName: '(UTC-08:00) Baja California'
  },
  {
    StandardName: 'UTC-08',
    DisplayName: '(UTC-08:00) Coordinated Universal Time-08'
  },
  {
    StandardName: 'Pacific Standard Time',
    DisplayName: '(UTC-08:00) Pacific Time (US & Canada)'
  },
  {
    StandardName: 'US Mountain Standard Time',
    DisplayName: '(UTC-07:00) Arizona'
  },
  {
    StandardName: 'Mountain Standard Time (Mexico)',
    DisplayName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan'
  },
  {
    StandardName: 'Mountain Standard Time',
    DisplayName: '(UTC-07:00) Mountain Time (US & Canada)'
  },
  {
    StandardName: 'Central America Standard Time',
    DisplayName: '(UTC-06:00) Central America'
  },
  {
    StandardName: 'Central Standard Time',
    DisplayName: '(UTC-06:00) Central Time (US & Canada)'
  },
  {
    StandardName: 'Easter Island Standard Time',
    DisplayName: '(UTC-06:00) Easter Island'
  },
  {
    StandardName: 'Central Standard Time (Mexico)',
    DisplayName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'
  },
  {
    StandardName: 'Canada Central Standard Time',
    DisplayName: '(UTC-06:00) Saskatchewan'
  },
  {
    StandardName: 'SA Pacific Standard Time',
    DisplayName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco'
  },
  {
    StandardName: 'Eastern Standard Time (Mexico)',
    DisplayName: '(UTC-05:00) Chetumal'
  },
  {
    StandardName: 'Eastern Standard Time',
    DisplayName: '(UTC-05:00) Eastern Time (US & Canada)'
  },
  {
    StandardName: 'Haiti Standard Time',
    DisplayName: '(UTC-05:00) Haiti'
  },
  {
    StandardName: 'Cuba Standard Time',
    DisplayName: '(UTC-05:00) Havana'
  },
  {
    StandardName: 'US Eastern Standard Time',
    DisplayName: '(UTC-05:00) Indiana (East)'
  },
  {
    StandardName: 'Turks And Caicos Standard Time',
    DisplayName: '(UTC-05:00) Turks and Caicos'
  },
  {
    StandardName: 'Paraguay Standard Time',
    DisplayName: '(UTC-04:00) Asuncion'
  },
  {
    StandardName: 'Atlantic Standard Time',
    DisplayName: '(UTC-04:00) Atlantic Time (Canada)'
  },
  {
    StandardName: 'Venezuela Standard Time',
    DisplayName: '(UTC-04:00) Caracas'
  },
  {
    StandardName: 'Central Brazilian Standard Time',
    DisplayName: '(UTC-04:00) Cuiaba'
  },
  {
    StandardName: 'SA Western Standard Time',
    DisplayName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
  },
  {
    StandardName: 'Pacific SA Standard Time',
    DisplayName: '(UTC-04:00) Santiago'
  },
  {
    StandardName: 'Newfoundland Standard Time',
    DisplayName: '(UTC-03:30) Newfoundland'
  },
  {
    StandardName: 'Tocantins Standard Time',
    DisplayName: '(UTC-03:00) Araguaina'
  },
  {
    StandardName: 'E. South America Standard Time',
    DisplayName: '(UTC-03:00) Brasilia'
  },
  {
    StandardName: 'SA Eastern Standard Time',
    DisplayName: '(UTC-03:00) Cayenne, Fortaleza'
  },
  {
    StandardName: 'Argentina Standard Time',
    DisplayName: '(UTC-03:00) City of Buenos Aires'
  },
  {
    StandardName: 'Greenland Standard Time',
    DisplayName: '(UTC-03:00) Greenland'
  },
  {
    StandardName: 'Montevideo Standard Time',
    DisplayName: '(UTC-03:00) Montevideo'
  },
  {
    StandardName: 'Magallanes Standard Time',
    DisplayName: '(UTC-03:00) Punta Arenas'
  },
  {
    StandardName: 'Saint Pierre Standard Time',
    DisplayName: '(UTC-03:00) Saint Pierre and Miquelon'
  },
  {
    StandardName: 'Bahia Standard Time',
    DisplayName: '(UTC-03:00) Salvador'
  },
  {
    StandardName: 'UTC-02',
    DisplayName: '(UTC-02:00) Coordinated Universal Time-02'
  },
  {
    StandardName: 'Mid-Atlantic Standard Time',
    DisplayName: '(UTC-02:00) Mid-Atlantic - Old'
  },
  {
    StandardName: 'Azores Standard Time',
    DisplayName: '(UTC-01:00) Azores'
  },
  {
    StandardName: 'Cape Verde Standard Time',
    DisplayName: '(UTC-01:00) Cabo Verde Is.'
  },
  {
    StandardName: 'UTC',
    DisplayName: '(UTC) Coordinated Universal Time'
  },
  {
    StandardName: 'GMT Standard Time',
    DisplayName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London'
  },
  {
    StandardName: 'Greenwich Standard Time',
    DisplayName: '(UTC+00:00) Monrovia, Reykjavik'
  },
  {
    StandardName: 'Sao Tome Standard Time',
    DisplayName: '(UTC+00:00) Sao Tome'
  },
  {
    StandardName: 'Morocco Standard Time',
    DisplayName: '(UTC+01:00) Casablanca'
  },
  {
    StandardName: 'W. Europe Standard Time',
    DisplayName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  },
  {
    StandardName: 'Central Europe Standard Time',
    DisplayName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  },
  {
    StandardName: 'Romance Standard Time',
    DisplayName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'
  },
  {
    StandardName: 'Central European Standard Time',
    DisplayName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
  },
  {
    StandardName: 'W. Central Africa Standard Time',
    DisplayName: '(UTC+01:00) West Central Africa'
  },
  {
    StandardName: 'Jordan Standard Time',
    DisplayName: '(UTC+02:00) Amman'
  },
  {
    StandardName: 'GTB Standard Time',
    DisplayName: '(UTC+02:00) Athens, Bucharest'
  },
  {
    StandardName: 'Middle East Standard Time',
    DisplayName: '(UTC+02:00) Beirut'
  },
  {
    StandardName: 'Egypt Standard Time',
    DisplayName: '(UTC+02:00) Cairo'
  },
  {
    StandardName: 'E. Europe Standard Time',
    DisplayName: '(UTC+02:00) Chisinau'
  },
  {
    StandardName: 'Syria Standard Time',
    DisplayName: '(UTC+02:00) Damascus'
  },
  {
    StandardName: 'West Bank Standard Time',
    DisplayName: '(UTC+02:00) Gaza, Hebron'
  },
  {
    StandardName: 'South Africa Standard Time',
    DisplayName: '(UTC+02:00) Harare, Pretoria'
  },
  {
    StandardName: 'FLE Standard Time',
    DisplayName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
  },
  {
    StandardName: 'Israel Standard Time',
    DisplayName: '(UTC+02:00) Jerusalem'
  },
  {
    StandardName: 'Kaliningrad Standard Time',
    DisplayName: '(UTC+02:00) Kaliningrad'
  },
  {
    StandardName: 'Sudan Standard Time',
    DisplayName: '(UTC+02:00) Khartoum'
  },
  {
    StandardName: 'Libya Standard Time',
    DisplayName: '(UTC+02:00) Tripoli'
  },
  {
    StandardName: 'Namibia Standard Time',
    DisplayName: '(UTC+02:00) Windhoek'
  },
  {
    StandardName: 'Arabic Standard Time',
    DisplayName: '(UTC+03:00) Baghdad'
  },
  {
    StandardName: 'Turkey Standard Time',
    DisplayName: '(UTC+03:00) Istanbul'
  },
  {
    StandardName: 'Arab Standard Time',
    DisplayName: '(UTC+03:00) Kuwait, Riyadh'
  },
  {
    StandardName: 'Belarus Standard Time',
    DisplayName: '(UTC+03:00) Minsk'
  },
  {
    StandardName: 'Russian Standard Time',
    DisplayName: '(UTC+03:00) Moscow, St. Petersburg'
  },
  {
    StandardName: 'E. Africa Standard Time',
    DisplayName: '(UTC+03:00) Nairobi'
  },
  {
    StandardName: 'Iran Standard Time',
    DisplayName: '(UTC+03:30) Tehran'
  },
  {
    StandardName: 'Arabian Standard Time',
    DisplayName: '(UTC+04:00) Abu Dhabi, Muscat'
  },
  {
    StandardName: 'Astrakhan Standard Time',
    DisplayName: '(UTC+04:00) Astrakhan, Ulyanovsk'
  },
  {
    StandardName: 'Azerbaijan Standard Time',
    DisplayName: '(UTC+04:00) Baku'
  },
  {
    StandardName: 'Russia Time Zone 3',
    DisplayName: '(UTC+04:00) Izhevsk, Samara'
  },
  {
    StandardName: 'Mauritius Standard Time',
    DisplayName: '(UTC+04:00) Port Louis'
  },
  {
    StandardName: 'Saratov Standard Time',
    DisplayName: '(UTC+04:00) Saratov'
  },
  {
    StandardName: 'Georgian Standard Time',
    DisplayName: '(UTC+04:00) Tbilisi'
  },
  {
    StandardName: 'Volgograd Standard Time',
    DisplayName: '(UTC+04:00) Volgograd'
  },
  {
    StandardName: 'Caucasus Standard Time',
    DisplayName: '(UTC+04:00) Yerevan'
  },
  {
    StandardName: 'Afghanistan Standard Time',
    DisplayName: '(UTC+04:30) Kabul'
  },
  {
    StandardName: 'West Asia Standard Time',
    DisplayName: '(UTC+05:00) Ashgabat, Tashkent'
  },
  {
    StandardName: 'Ekaterinburg Standard Time',
    DisplayName: '(UTC+05:00) Ekaterinburg'
  },
  {
    StandardName: 'Pakistan Standard Time',
    DisplayName: '(UTC+05:00) Islamabad, Karachi'
  },
  {
    StandardName: 'Qyzylorda Standard Time',
    DisplayName: '(UTC+05:00) Qyzylorda'
  },
  {
    StandardName: 'India Standard Time',
    DisplayName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'
  },
  {
    StandardName: 'Sri Lanka Standard Time',
    DisplayName: '(UTC+05:30) Sri Jayawardenepura'
  },
  {
    StandardName: 'Nepal Standard Time',
    DisplayName: '(UTC+05:45) Kathmandu'
  },
  {
    StandardName: 'Central Asia Standard Time',
    DisplayName: '(UTC+06:00) Astana'
  },
  {
    StandardName: 'Bangladesh Standard Time',
    DisplayName: '(UTC+06:00) Dhaka'
  },
  {
    StandardName: 'Omsk Standard Time',
    DisplayName: '(UTC+06:00) Omsk'
  },
  {
    StandardName: 'Myanmar Standard Time',
    DisplayName: '(UTC+06:30) Yangon (Rangoon)'
  },
  {
    StandardName: 'SE Asia Standard Time',
    DisplayName: '(UTC+07:00) Bangkok, Hanoi, Jakarta'
  },
  {
    StandardName: 'Altai Standard Time',
    DisplayName: '(UTC+07:00) Barnaul, Gorno-Altaysk'
  },
  {
    StandardName: 'W. Mongolia Standard Time',
    DisplayName: '(UTC+07:00) Hovd'
  },
  {
    StandardName: 'North Asia Standard Time',
    DisplayName: '(UTC+07:00) Krasnoyarsk'
  },
  {
    StandardName: 'N. Central Asia Standard Time',
    DisplayName: '(UTC+07:00) Novosibirsk'
  },
  {
    StandardName: 'Tomsk Standard Time',
    DisplayName: '(UTC+07:00) Tomsk'
  },
  {
    StandardName: 'China Standard Time',
    DisplayName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
  },
  {
    StandardName: 'North Asia East Standard Time',
    DisplayName: '(UTC+08:00) Irkutsk'
  },
  {
    StandardName: 'Singapore Standard Time',
    DisplayName: '(UTC+08:00) Kuala Lumpur, Singapore'
  },
  {
    StandardName: 'W. Australia Standard Time',
    DisplayName: '(UTC+08:00) Perth'
  },
  {
    StandardName: 'Taipei Standard Time',
    DisplayName: '(UTC+08:00) Taipei'
  },
  {
    StandardName: 'Ulaanbaatar Standard Time',
    DisplayName: '(UTC+08:00) Ulaanbaatar'
  },
  {
    StandardName: 'Aus Central W. Standard Time',
    DisplayName: '(UTC+08:45) Eucla'
  },
  {
    StandardName: 'Transbaikal Standard Time',
    DisplayName: '(UTC+09:00) Chita'
  },
  {
    StandardName: 'Tokyo Standard Time',
    DisplayName: '(UTC+09:00) Osaka, Sapporo, Tokyo'
  },
  {
    StandardName: 'North Korea Standard Time',
    DisplayName: '(UTC+09:00) Pyongyang'
  },
  {
    StandardName: 'Korea Standard Time',
    DisplayName: '(UTC+09:00) Seoul'
  },
  {
    StandardName: 'Yakutsk Standard Time',
    DisplayName: '(UTC+09:00) Yakutsk'
  },
  {
    StandardName: 'Cen. Australia Standard Time',
    DisplayName: '(UTC+09:30) Adelaide'
  },
  {
    StandardName: 'AUS Central Standard Time',
    DisplayName: '(UTC+09:30) Darwin'
  },
  {
    StandardName: 'E. Australia Standard Time',
    DisplayName: '(UTC+10:00) Brisbane'
  },
  {
    StandardName: 'AUS Eastern Standard Time',
    DisplayName: '(UTC+10:00) Canberra, Melbourne, Sydney'
  },
  {
    StandardName: 'West Pacific Standard Time',
    DisplayName: '(UTC+10:00) Guam, Port Moresby'
  },
  {
    StandardName: 'Tasmania Standard Time',
    DisplayName: '(UTC+10:00) Hobart'
  },
  {
    StandardName: 'Vladivostok Standard Time',
    DisplayName: '(UTC+10:00) Vladivostok'
  },
  {
    StandardName: 'Lord Howe Standard Time',
    DisplayName: '(UTC+10:30) Lord Howe Island'
  },
  {
    StandardName: 'Bougainville Standard Time',
    DisplayName: '(UTC+11:00) Bougainville Island'
  },
  {
    StandardName: 'Russia Time Zone 10',
    DisplayName: '(UTC+11:00) Chokurdakh'
  },
  {
    StandardName: 'Magadan Standard Time',
    DisplayName: '(UTC+11:00) Magadan'
  },
  {
    StandardName: 'Norfolk Standard Time',
    DisplayName: '(UTC+11:00) Norfolk Island'
  },
  {
    StandardName: 'Sakhalin Standard Time',
    DisplayName: '(UTC+11:00) Sakhalin'
  },
  {
    StandardName: 'Central Pacific Standard Time',
    DisplayName: '(UTC+11:00) Solomon Is., New Caledonia'
  },
  {
    StandardName: 'Russia Time Zone 11',
    DisplayName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky'
  },
  {
    StandardName: 'New Zealand Standard Time',
    DisplayName: '(UTC+12:00) Auckland, Wellington'
  },
  {
    StandardName: 'UTC+12',
    DisplayName: '(UTC+12:00) Coordinated Universal Time+12'
  },
  {
    StandardName: 'Fiji Standard Time',
    DisplayName: '(UTC+12:00) Fiji'
  },
  {
    StandardName: 'Kamchatka Standard Time',
    DisplayName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'
  },
  {
    StandardName: 'Chatham Islands Standard Time',
    DisplayName: '(UTC+12:45) Chatham Islands'
  },
  {
    StandardName: 'UTC+13',
    DisplayName: '(UTC+13:00) Coordinated Universal Time+13'
  },
  {
    StandardName: 'Tonga Standard Time',
    DisplayName: "(UTC+13:00) Nuku'alofa"
  },
  {
    StandardName: 'Samoa Standard Time',
    DisplayName: '(UTC+13:00) Samoa'
  },
  {
    StandardName: 'Line Islands Standard Time',
    DisplayName: '(UTC+14:00) Kiritimati Island'
  }
];
