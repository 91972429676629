/*
 * @Description: 接口集合
 * @Author: Summer.Chen
 * @Date: 2024-01-26 15:12:04
 * @LastEditTime: 2024/11/08 09:49:02
 * @LastEditors: Please set LastEditors
 */
import http from '@/utils/http';
import util from '../utils/util';
const api = util.getPreAPI();
// 系统登录
export function loginByUsername(username, password) {
  return http.post('/usercenter/cloud/user/login', { username, password }, 'spin');
}
// 退出登录
export function logout() {
  return http.post('/ci-base/account/logout', {}, 'spin');
}
// 刷新token接口
export function reFreshToken() {
  return http.get('/auth/refreshtoken', {}, 'spin');
}
// 获取用户信息接口
export function getUserInfo() {
  return http.get('/ci-web/userInfo/getUserInfo', {});
}
// 获取用户的左侧菜单
export function getAccessMenu(params) {
  return http.get('/usercenter/menu/route', params, 'spin');
}

// 获取站点列表
export function getAllSystem(params) {
  return http.get('/ci-web/depot', params);
}
// 获取BMS站点列表
export function getAllBMSSystem(params) {
  return http.get('/ci-web/bms/depot/getDepotList', params);
}
// 根据essSN获取电池簇数据
export function getBatteryClusterDataList(data) {
  return http.get('/ci-web/energyStorage/getBatteryClusterData', data);
}
/* 获取BMS电池簇数据 */
export function getBmsClusterData(params) {
  return http.get('/ci-web/bms/battery/getBmsClusterData', params);
}
// 获取场站中所有的电池堆、簇树形数据
export function getBatteryStackAndClurData(params) {
  return http.get('/ci-web/bms/depot/getBatteryDeviceList', params);
}
/* 获取BMS电池包数据 */
export function getBatteryPackData(params) {
  return http.get('/ci-web/bms/battery/getBmsPackData', params);
}
/* 获取BMS的电芯温度曲线和电压温度曲线 */
export function getBmsPackDetailData(params) {
  return http.get('/ci-web/bms/battery/getBmsPackDetailData', params);
}

/* 获取电池簇，电压和温度曲线数据 */
export function getBmsClusterDetailData(params) {
  return http.get('/ci-web/bms/battery/getBmsClusterDetailData', params);
}

/* 获取电池堆数据 */
export function getBmsStackData(params) {
  return http.get('/ci-web/bms/battery/getBmsStackData', params);
}
export function getSubDevData(params) {
  return http.get('/ci-web/deviceList/getSubDevData', params);
}
export function getOverViewData(sysSn) {
  return http.get('/ci-web/systemList/getOverviewData', { sysSn: sysSn });
}
export function getOtaUrl() {
  return http.get('/ci-web/ota/getOtaUrl', {});
}
// 获取功率图数据new
export function getEssPowers4Depot(data) {
  return http.get('/ci-web/energyInfo4Depot/getEssPowers4Depot', data);
}
// 保存场站功率曲线配置
export function saveEssPowerSetting(data) {
  return http.post('/ci-web/energyInfo4Depot/saveEssPowerSetting', data);
}
// 获取场站下所有的站点
export function getSNListByDepotId(params) {
  return http.get('/ci-web/depot/getSNs', params);
}
/* 运行数据导出 */
export function exportRunningData(params) {
  return http.resetExportExelTempGet(
    '/ci-web/bms/battery/exportRunningData',
    params
  );
}
/* 获取数据包类型 */
export function getBatteryDeviceRunningDataType(params) {
  return http.get('/ci-web/bms/battery/getDeviceRunningDataType', params);
}
/* 获取场站中所有设备列表 */
export function getAllDeviceList(params) {
  return http.get('/ci-web/bms/depot/getAllDeviceList', params);
}

/* 远程控制 */
export function setOperation(data) {
  return http.post('/ci-web/bms/push/operation', data);
}

/* bms获取运行数据配置信息 */
export function getBmsRunningDatas(params) {
  return http.get('/ci-web/bms/battery/getRunningData', params);
}
/* bms获取秒级数据列表 */
export function getBmsSecondsData(params) {
  return http.get('/ci-web/bms/battery/getSecondDataList', params);
}
/* bms电池运行数据列表 */
export function getBmsRunningList(params) {
  return http.get('/ci-web/bms/battery/getRunningDataList', params);
}
/* 获取场站中运行数据表头 */
export function getRunningDataTableTittle(params) {
  return http.get('/ci-web/systemData/getRunningDataTableTittle', params);
}
/* 获取运行数据列表 */
export function getRunningDataList(params) {
  return http.get('/ci-web/systemData/getRunningDataList', params);
}
/* 获取秒级数据列表 */
export function getSecondDataList(params) {
  return http.get('/ci-web/systemData/getSecondDataList', params);
}
/* 推送配置文件 */
export function pushConfig(data) {
  return http.post('/ci-web/bms/push/pushConfig', data);
}

// 获取Bms上云场站下所有的站点
export function getBmsSnListByDepotId(params) {
  return http.get('/ci-web/depot/getSNs', params);
}
// 获取Bms上云电量统计
export function getBmsBatteryEnergy(data) {
  return http.post('/ci-web/bms/battery/energy', data);
}
// 获取Bms上云功率统计
export function getBmsPowerEnergy(data) {
  return http.post('/ci-web/bms/battery/power', data);
}
// 获取场站下所有的充电桩
export function getChargingPileDataByDepotId(data) {
  return http.get('/ci-web/chargePile/detail', data);
}
// 启动或停止充电桩充电状态
export function startOrStopChargingPile(data) {
  return http.post(
    `/ci-web/chargePile/charge?devSn=${data.devSn}&start=${data.flag}`,
    {}
  );
}
// 根据场站ID获取充电桩SN列表
export function getChargingPileListByDepotId(params) {
  return http.get('/ci-web/chargePile/getSnsByDepotId', params);
}
// 根据充电桩SN获取系统设置数据
export function getChargingPileSettings(params) {
  return http.get('/ci-web/chargePile/getSettingBySn', params);
}
// 获取充电桩工作模式数据
export function getWorkModeList() {
  return http.get('/ci-web/dataDict/getKVList?dictType=charge_pile_type', {});
}
// 保存充电桩系统设置数据
export function saveChargingPileSettings(data) {
  return http.post('/ci-web/chargePile/set', data);
}
// 能量统计 new
export function getEnergy4Depot(data) {
  return http.get('/ci-web/energyInfo4Depot/getEnergy4Depot', data);
}
// 获取系统主页面头部信息
export function getEssMainInfo4Depot(data) {
  return http.get('/ci-web/energyInfo4Depot/getEssMainInfo4Depot', data);
}
// 获取系统主页面头部信息
export function getDepotStatistic(params) {
  return http.get('/ci-web/bms/depot/depotStatistic', params);
}
// 获取站点秒级数据
export function getLatestDepotPowerData(data) {
  return http.get('/ci-web/systemMainPage/getLatestDepotPowerData', data);
}
// 获取首页能量统计信息
export function captureEnergyStatistics() {
  return http.get('/ci-web/energyInfo4Depot/getHomePageEneryStatistics4Depot');
}
/* 获取BMS的首页数据 */
export function getBmsHomePageData() {
  return http.get('/ci-web/bms/depot/homePageStatistic');
}
// 获取近五个月的收益统计数据
export function getIncome5(depotId) {
  return http.get(
    '/ci-web/systemMainPage/getLatestFiveMonthIncome',
    depotId,
    'spin'
  );
}
// 获取自安装起的收益分析数据
export function getIncomeAnalysisFromInstall(depotId) {
  return http.post(
    `/ci-web/incomeStatistics/fromInstall?depotId=${depotId}`,
    {},
    'spin'
  );
}
// 按年获取收益分析数据
export function getIncomeAnalysisYear(depotId, year) {
  return http.post('/ci-web/incomeStatistics/year', { depotId, year }, 'spin');
}
// 按月获取收益分析数据
export function getIncomeAnalysisMonth(depotId, monthDate) {
  return http.post(
    '/ci-web/incomeStatistics/month',
    { depotId, monthDate },
    'spin'
  );
}
// 获取pcs数据
export function getPCSData(depotId) {
  return http.get('/ci-web/energyStorage/getPCSData', { depotId });
}
// 客诉系统对接时获取token
export function getCustomerComplaintToken() {
  return http.post('/ci-web/customComplaintAbutment', {});
}
export function executeCommandIssue(data) {
  return http.post('/ci-web/commandIssue', data);
}
// 场站
// 通道列表
export function getChannelList(params) {
  return http.get('/ci-web/depot/getAllScada', params);
}
// 系统列表
export function getChannelListBms(params) {
  return http.get('/ci-web/bms/depot/depotDevice', params);
}
/**
 * @Author: YuKi
 * @description: 获取选择设备源数据
 * @param {*} data
 * @return {*}
 */
export function queryDviceListData(data) {
  return http.get('/ci-web/device/getDevList', data);
}

// 获取设备参数配置可配置子设备 ---设备参数配置
export function getCanSetDevice_one(scadaSn) {
  return http.get('/ci-web/setting/getCanSetDevice', { scadaSn });
}

/**
 * @description: 获取设备
 * @return {*}
 * @LastEditors: XuAnjie
 */
export function getDeviceList(emsSn) {
  return http.get('/ci-web/device/getAllDevList', { emsSn });
}

/**
 * @Author: YuKi
 * @description: 根据子通道获取设备参数配置可配置子设备
 * @param {*} emsSn
 * @return {*}
 */
export function getCanSetDevice(emsSn) {
  return http.get('/ci-web/setting/getCanSetDeviceList', { emsSn });
}

// 根据主通道获取子通道
export function getDepotEmsSn(scadaSn) {
  return http.get('/ci-web/depot/getEmsSn', { scadaSn });
}

// 获取设备参数配置可配置子设备的参数
export function getDeviceCanSetParam(subtype) {
  return http.get('/ci-web/setting/getDeviceCanSetParam', { subtype });
}

// 获取设备参数配置可配置子设备的参数
export function getDeviceCanSetParamById(params) {
  return http.get('/ci-web/setting/getDeviceCanSetParam', params);
}

// 获取设备参数配置可配置子设备的参数(分类)
export function getEmsCanSetParam(params) {
  return http.get('/ci-web/setting/getEmsCanSetParam', params);
}

// 获取设备参数配置可配置子设备的参数(维护参数用)
export function getDeviceCanSetMaintainParam(subtype) {
  return http.get('/ci-web/setting/getDeviceCanSetMaintainParam', {
    subtype
  });
}
// 获取导出数据类型
export function getDeviceRunningDataType(subtype) {
  return http.get('/ci-web/setting/getDeviceRunningDataType', { subtype });
}
// 组态
// 获取SN列表
//获取场站下的所有SN
export function depotSNList() {
  const depotId = localStorage.getItem('depotId');
  return http.get('/ci-web/depot/getSNs', { depotId });
}
export function addTopo(data) {
  return http.post('/ci-web/topology/saveOrUpdate', data);
}
// 保存更新拓扑图
export function saveOrUpdate(data) {
  return http.post('/ci-web/topology/saveOrUpdate', data);
}
// 保存更新拓扑图模板
export function saveOrUpdateTemplate(data) {
  return http.post('/ci-web/topology/saveOrUpdateTemplate', data);
}
export function getSettingData(depotId) {
  return http.get('/ci-web/topology/setting', { depotId });
}
// 根据模板id获取拓扑图
export function getTemplateById(templateId) {
  return http.get('/ci-web/topology/getTemplateById', { templateId });
}
export function getGraphResultData(depotId) {
  return http.get('/ci-web/topology/data', { depotId });
}
// 新增设备
export function addDevice(data) {
  return http.post('/ci-web/device/add', data);
}
// 更新设备
export function updateDevice(data) {
  return http.post('/ci-web/device/update', data);
}
// 删除设备
export function deleteDevice(data) {
  return http.post('/ci-web/device/delete', data);
}
// 获取可添加设备类型
export function getAddDeviceType(data) {
  return http.get('/ci-web/setting/getAddDeviceType', { data });
}
// 设备类型获取协议及型号
export function getDeviceProtocolAndModel(subtype) {
  return http.get('/ci-web/setting/getDeviceProtocolAndModel', { subtype });
}
// 获取通道下所有设备类型及子设备类型
export function getDeviceInScada(scadaSn) {
  return http.get('/ci-web/setting/getDeviceInScada', { scadaSn });
}
// 查询设备配置
export function getDeviceInfoByScadaSnAndId(deviceId, scadaSn, emsSn) {
  return http.get('/ci-web/device/getDeviceInfoByScadaSnAndId', {
    deviceId,
    scadaSn,
    emsSn
  });
}
/**
 * @Author: YuKi
 * @description: 获取通道下所有设备类型及子设备类型
 * @param {*} data
 * @return {*}
 */
export function getDevListData(data) {
  return http.get('/ci-web/setting/getDeviceInScada', data);
}
export function getDeviceRunningDataTypeData(params) {
  return http.get('/ci-web/setting/getDeviceRunningDataType', params);
}
export function getRunningData(params) {
  return http.get('/ci-web/systemData/getRunningData', params);
}

/**
 * @Author: YuKi
 * @description: 通过子设备获取硬件端口列表接口
 * @param {*} params
 * @return {*}
 */
export function getHwPortSetting(params) {
  return http.get('/ci-web/setting/getHwPortSetting', params);
}

/**
 * @Author: YuKi
 * @description: 通过主通道获取子通道id
 * @param {*} params
 * @return {*}
 */
export function getEmsSn(params) {
  return http.get('/ci-web/depot/getEmsSn', params);
}

/**
 * @Author: YuKi
 * @description: 请求设备列表
 * @param {Object} params 接口传参
 * @return {*}
 */
export function requestDeviceList(params) {
  return http.get('/ci-web/device/requestDeviceList', params);
}

/**
 * @Author: YuKi
 * @description: 请求电池堆
 * @param {Object} params 接口传参
 * @return {*}
 */
export function getStackList(params) {
  return http.get('/ci-web/device/getStackList', params);
}

// 根据设备大类、子类、秒级数据标识获取jsonkey
export function getDeviceJsonKey(data) {
  return http.get('/ci-web/device/getDeviceJsonKey', data);
}

// 注销账户发送验证码
export function sendCancelAccountCode(data) {
  return http.post('/ci-base/account/sendCancelAccountCode', data);
}
// 用户信息设置 - 修改用户邮箱时获取验证码
export function getEmailSettingCode(params) {
  return http.get('/ci-base/account/getEmailSettingCode', params);
}
// 用户信息设置 - 修改用户邮箱
export function editUserEmail(params) {
  return http.put('/ci-base/account/editUserEmail', params);
}
// 用户信息设置 - 修改用户邮箱
export function checkCancelAccountCode(data) {
  return http.post('/ci-base/account/checkCancelAccountCode', data);
}
// 用户信息设置 - 注销账户
export function cancelAccounts() {
  return http.post('/ci-base/account/cancelAccount');
}
// 升级列表-查询列表
export function getFirmwareList(params) {
  return http.get('/ci-web/ota/getFirmwareList', params);
}
// 升级列表-获取所属区域
export function getRegion(params) {
  return http.get('/ci-web/ota/getRegion', params);
}

// 升级列表-获取所属产品
export function getTheProduct(params) {
  return http.get('/ci-web/ota/getTheProduct', params);
}

// 升级列表-获取模块名称
export function getModule(params) {
  return http.get('/ci-web/ota/getModule', params);
}

// 升级列表-获取升级包详情
export function getFirmwareDetail(params) {
  return http.get('/ci-web/ota/getFirmwareDetail', params);
}

// 升级列表-删除升级包
export function deleteOtaFirmware(params) {
  return http.delete('/ci-web/ota/deleteOtaFirmware', params);
}
// 升级列表-删除升级包
export function getBatchUpgradeList(params) {
  return http.get('/ci-web/ota/getBatchUpgradeList', params);
}
// 升级列表-根据模块名称和产品Key获取支持版本
export function getSupportedVersion(params) {
  return http.get('/ci-web/ota/getSupportedVersion', params);
}
// 升级列表-根据模块名称和产品Key获取支持版本
export function upgrade(data) {
  return http.post('/ci-web/ota/upgrade', data);
}
// 升级列表-查询历史升级列表
export function getHistoryFirmwareList(params) {
  return http.get('/ci-web/ota/getUpgradeRecordList', params);
}
// 升级列表-查询历史升级列表
export function recordExecuteHistory(data) {
  return http.post('/ci-web/ota/recordExecute', data);
}

// 升级列表-查询历史升级列表
export function deleteUpgradeRecord(params) {
  return http.delete('/ci-web/ota/deleteUpgradeRecord', params);
}
// 模块列表-查询模块信息
export function getDataList(params) {
  return http.get('/ci-web/moduleManage/select', params);
}
// 模块列表-删除模块
export function deleteModule(data) {
  return http.delete('/ci-web/moduleManage/delete', '', data);
}
// 模块列表-新增模块
export function addModule(data) {
  return http.post('/ci-web/moduleManage/add', data);
}
// 模块列表-修改模块
export function updateModule(data) {
  return http.put('/ci-web/moduleManage/update', data);
}
// 升级列表-添加升级包
export function addOtaFirmware(data) {
  return http.post('/ci-web/ota/addOtaFirmware', data);
}
//BAMS(堆)实时数据
export function realDataStack() {
  return http.get('/realtime/bams');
}
//BAMS(簇)实时数据
export function realDataCluster(params) {
  return http.get('/realtime/bcmu', params);
}
//BAMS(包)实时数据
export function realDataPackage(params) {
  return http.get('/realtime/blmu', params);
}
//告警信号实时数据
export function realDataWarning() {
  return http.get('/realtime/fault');
}
// 批量升级-查询列表
export function apiGetCircularUpgradeList(params) {
  return http.get('/ci-web/ota/getCircularUpgradeList', params);
}

// 批量升级-获取可升级的scadaSn
export function apiGetUpgradeScadaSn() {
  return http.get('/ci-web/ota/getUpgradeScadaSn');
}

// 批量升级-根据sn获取模块列表(sn为空获取所有模块)
export function apiGetModuleByScadaSn(params) {
  return http.get('/ci-web/ota/getModuleByScadaSn', params);
}

// 批量升级-获取sn可用的升级包
export function apiGetUpgradeFirmware(params) {
  return http.get('/ci-web/ota/getUpgradeFirmware', params);
}

// 批量升级-添加升级包
export function apiCreateCircularUpgrade(data) {
  return http.post('/ci-web/ota/createCircularUpgrade', data);
}

// 批量升级-删除与批量删除
export function apiDeleteCircularUpgrade(params) {
  return http.delete('/ci-web/ota/deleteCircularUpgrade', params);
}

// 上传文件
export function apiUpload(data) {
  return http.post('/ci-web/file/upload', data);
}

// 产品建议-获取产品建议列表
export function apiGetSuggestionPage(params) {
  return http.get('/ci-web/productSuggestion/getSuggestionPage', params);
}

// 产品建议-新增产品建议
export function apiAddSuggestion(data) {
  return http.post('/ci-web/productSuggestion/addSuggestion', data);
}

// 产品建议-编辑产品建议
export function apiEditSuggestion(data) {
  return http.put('/ci-web/productSuggestion/editSuggestion', data);
}

// 产品建议-删除产品建议
export function apiDeleteSuggestion(params) {
  return http.delete('/ci-web/productSuggestion/deleteSuggestion', params);
}

// nmi管理-获取nmi设置
export function apiGetNmi(params) {
  return http.get('/ci-web/nmi/getNmi', params);
}

// nmi管理-根据场站id获取绑定的sn
export function apiGetNmiSn(params) {
  return http.get('/ci-web/nmi/getNmiSn', params);
}

// nmi管理-是否注册DynamicExport
export function apiGetRegisterDynamicExport(params) {
  return http.get('/ci-web/nmi/getRegisterDynamicExport', params);
}

// nmi管理-获取注册状态
export function apiGetRegisterStatus(params) {
  return http.get('/ci-web/nmi/getRegisterStatus', params);
}

// nmi管理-更新nmi注册信息
export function apiUpdateNmi(data) {
  return http.post('/ci-web/nmi/updateNmi', data);
}

// nmi管理-获取Agent列表*/
export function apiGetAgentByState(params) {
  return http.get('/iterate/ess/getAgentByState', params);
}
//BMS运行数据导出
export function exportBMSRunningData(params) {
  return http.resetExportExelTemp(
    api + '/ci-web/bms/battery/exportRunningData',
    params
  );
}
// BMS电池包数据---电池簇数据
export function queryBMSBatteryCluster(params) {
  return http.get('/ci-web/bms/depot/getBatteryDeviceList', params);
}
// BMS 售后、管理员 获取电池包数据
export function getBatteryPackFurtherData(params) {
  return http.get('/ci-web/bms/battery/getBmsPackFurtherData', params);
}
// BMS 辅控信息-获取辅控设备类型
export function getAssistType(params) {
  return http.get('/ci-web/bms/battery/getAssistType', params);
}
//BMS 辅控信息数据
export function getAssistData(params) {
  return http.get('/ci-web/bms/battery/getAssistData', params);
}
//BMS 事件日志
export function queryEventLogData(params) {
  return http.get('/ci-web/bms/log/getBmsEventList', params);
}
// BMS 运行  断点续传
export function resumeUploadFromBreakpoint(params) {
  return http.post('/ci-web/bms/push/transmission', params);
}

// BMS 运行日志
export function queryBmsRunningLogList(params) {
  return http.get('/ci-web/bms/log/getBmsRunningLogList', params);
}

//场站运行数据/设备运行数据---秒级数据导出
export function exportSecondsData(params) {
  return http.resetExportExelTemp(
    api + '/ci-web/export/exportSecondData',
    params
  );
}
/* 储能系统维护-PCS远程参数设置 */
/**
 * @description: 获取 PCS 的 sn 信息
 * @Author: Summer.Chen
 * @Date: 2024-05-14 10:34:05
 * @return {*}
 * @LastEditTime: Do not edit
 * @LastEditors: Summer.Chen
 * @param {emsSn  子通道 required  scadaSn 主通道  required } params
 */
export function getPcsSn(params) {
  return http.get('/ci-web/pcsRemoteSet/getPcsSn', params);
}
// 设置 PCS 参数配置
export function setPcsConfig(data) {
  return http.post('/ci-web/pcsRemoteSet/setPcsConfig', data);
}
//获取 PCS 的 配置信息
export function setPcsConfigInfo(params) {
  return http.get('/ci-web/pcsRemoteSet/getPcsConfig', params);
}
//日志列表-设备故障日志-请求日志
export function deviceFaultLog(data) {
  return http.post('/ci-web/deviceFaultLog', data);
}
//日志列表-设备故障日志-查询
export function deviceFaultLogList(data) {
  return http.get('/ci-web/deviceFaultLog', data);
}
//文件下载  载接口，传入文件全路径
export function downloadFile(params) {
  return http.exportFileTemp({
    url: '/ci-web/file/fileDownload',
    params,
    method: 'get'
  });
}
// 刷新数据
export function refreshOTAData(params) {
  return http.get('/ci-web/ota/ListOTAModuleVersionsByDevice', params);
}
