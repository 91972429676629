/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:12:40
 */
import zh_energyStorageData from './zh-CN/translation.json';
import en_energyStorageData from './en-US/translation.json';
import de_energyStorageData from './de-DE/translation.json';

export const langEnergyStorageData = {
  zh_energyStorageData,
  en_energyStorageData,
  de_energyStorageData
};
