/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:14:50
 */
import zh_systemList from './zh-CN/translation.json';
import en_systemList from './en-US/translation.json';
import de_systemList from './de-DE/translation.json';

export const langSystemList = {
  zh_systemList,
  en_systemList,
  de_systemList
};
