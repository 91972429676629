import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import PageRouters from '@/routers/PageRouters';
import AlphaESSSpin from '@/containers/AlphaESSSpin';
import { hot } from 'react-hot-loader';

const App = () => (
  <Provider store={store}>
    <AlphaESSSpin pageRouters={PageRouters}></AlphaESSSpin>
  </Provider>
);

export default hot(module)(App);
