/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/03/28 11:23:52
 * @LastEditTime: 2023-11-08 16:08:14
 */
import React from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import RegisterSuccess from '@/pages/RegisterSuccess';
import ForgetUserName from '@/pages/ForgetUserName';
import ForgetPassword from '@/pages/ForgetPassword';
import ResetPassword from '@/pages/ResetPassword';
import NewResetPassword from '@/pages/NewResetPassword';
import Page404 from '@/pages/Page404';
import { connect } from 'react-redux';
let Layout = null;
//process.env.REACT_APP_LAYOUT_MODE不在definePlugin里配，两个都会打包
if (process.env.REACT_APP_LAYOUT_MODE === 'tab') {
  Layout = require('@/layout/TabMode').default;
} else {
  Layout = require('@/layout/App').default;
}
const mapStateToProps = state => {
  let { dataType } = state.app;
  return { dataType };
};

export default connect(mapStateToProps)(props => (
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Redirect
            to={props.dataType == 'local' ? '/app/realData' : '/app/home'}
            push
          />
        )}
      />
      <Route path="/app" component={Layout} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/RegisterActive" component={RegisterSuccess} />
      <Route exact path="/forgetUserName" component={ForgetUserName} />
      <Route exact path="/forgetPassword" component={ForgetPassword} />
      <Route exact path="/resetPassword" component={ResetPassword} />
      <Route exact path="/newResetPassword" component={NewResetPassword} />
      <Route component={Page404} />
    </Switch>
  </Router>
));
