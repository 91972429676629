/*
 * @Description: 数据中心列表
 * @Author: April
 * @Date: 2024-09-27 11:39:18
 */
// 获取当前浏览器地址栏的URL
const currentURL = window.location.href;
export const dataCenterHuiduList = [
  {
    url: 'https://huiducni.alphaess.com/',
    label: '智能dns',
    key: ''
  },
  {
    url: 'https://huidu-sgcni.alphaess.com/',
    label: '新加坡',
    key: 'SG'
  },
  {
    url: 'https://huidu-auscni.alphaess.com/',
    label: '澳洲',
    key: 'AU'
  },
  {
    url: 'https://huidu-eurcni.alphaess.com/',
    label: '欧洲',
    key: 'EU'
  }
];
export const dataCenterProductionList = [
  {
    url: 'https://cni.alphaess.com/',
    label: '智能dns',
    key: ''
  },
  {
    url: 'https://sgcni.alphaess.com/',
    label: '新加坡',
    key: 'SG'
  },
  {
    url: 'https://auscni.alphaess.com/',
    label: '澳洲',
    key: 'AU'
  },
  {
    url: 'https://eurcni.alphaess.com/',
    label: '欧洲',
    key: 'EU'
  }
];
export const dataCenterList = currentURL.includes('huidu')
  ? dataCenterHuiduList
  : dataCenterProductionList;

/** 多数据中心sdc映射 */
const dataCenterMap = new Map([
  [10, 'CN'],
  [11, 'SG'],
  [20, 'EU'],
  [30, 'AU']
]);
// 灰度
const huiduDataCenterURLs = {
  CN: 'https://huidu-cncni.alphaess.com/',
  SG: 'https://huidu-sgcni.alphaess.com/',
  EU: 'https://huidu-eurcni.alphaess.com/',
  AU: 'https://huidu-auscni.alphaess.com/'
};
// 正式
const proDataCenterURLs = {
  CN: 'https://cni.alphaess.com/',
  SG: 'https://sgcni.alphaess.com/',
  EU: 'https://eurcni.alphaess.com/',
  AU: 'https://auscni.alphaess.com/'
};

export const getDataCenterURL = sdc => {
  const key = typeof sdc === 'number' ? dataCenterMap.get(sdc) : sdc;
  if (currentURL.includes('huidu')) {
    return huiduDataCenterURLs[key];
  } else {
    return proDataCenterURLs[key];
  }
};
