/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:13:54
 */
import zh_mainPage from './zh-CN/translation.json';
import en_mainPage from './en-US/translation.json';
import de_mainPage from './de-DE/translation.json';

export const langMainPage = {
  zh_mainPage,
  en_mainPage,
  de_mainPage
};
