/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:21:59
 * @LastEditTime: 2023/12/25 19:11:19
 */
import zh_beforeLogin from './zh-CN/translation.json';
import en_beforeLogin from './en-US/translation.json';
import de_beforeLogin from './de-DE/translation.json';

export const langBeforeLogin = {
  zh_beforeLogin,
  en_beforeLogin,
  de_beforeLogin
};
