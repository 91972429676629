/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Summer.Chen
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2024-05-16 10:29:16
 */
import en_energyStorageSystemMaintenance from './en-US/translation.json';
import zh_energyStorageSystemMaintenance from './zh-CN/translation.json';
import de_energyStorageSystemMaintenance from './de-DE/translation.json';

export const energyStorageSystemMaintenance = {
  en_energyStorageSystemMaintenance,
  zh_energyStorageSystemMaintenance,
  de_energyStorageSystemMaintenance
};
