/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:12:55
 */
import zh_event from './zh-CN/translation.json';
import en_event from './en-US/translation.json';
import de_event from './de-DE/translation.json';

export const langEvent = { zh_event, en_event, de_event };
