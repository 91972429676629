import http from '@/utils/http';
export default {
  //获取角色列表
  getRoleList(params) {
    return http.get('/ci-web/getAllRoles', params, 'spin');
  },
  //获取所有菜单
  getMenuData(params) {
    return http.get('/ci-web/getAllPrivilegesAsTree', params, 'spin');
  },
  //获取日志
  getRungnigLogList(params) {
    return http.get('/ci-web/log/userLog', params, 'spin');
  },
  // 获取控制策略的设置内容
  getControlStrategyData(params) {
    return http.get('/ci-web/essConfig', params, 'spin');
  },
  //设置发电机参数
  saveGenerator(data) {
    return http.put('/ci-web/essConfig/setGenerator', data, 'spin');
  },
  //获取需量管理配置
  getDemandParam(params) {
    return http.get('/ci-web/setting/getDemandParam', params, 'spin');
  },
  //获取自发自用配置
  getSelfUseParam(params) {
    return http.get('/ci-web/setting/getSelfUseParam', params, 'spin');
  },
  //设置需量管理
  saveConfig(data) {
    return http.post('/ci-web/push/config', data);
  },
  //推送维护参数
  saveMaintain(data) {
    return http.post('/ci-web/push/maintain', data);
  },
  //推送更新设备维护参数
  saveUpdateMaintain(data) {
    return http.post('/ci-web/push/requestConfig', data);
  },
  //推送断网续传维护参数
  saveResumeMaintain(data) {
    return http.post('/ci-web/push/transmission', data);
  },
  //设置自发自用模式
  savePrivateUse(data) {
    return http.put('/ci-web/essConfig/setSelfUse', data, 'spin');
  },
  //设置时间段充放电
  saveCharge(data) {
    return http.put('/ci-web/essConfig/setTimeCharge', data, 'spin');
  },
  //设置削峰填谷
  setPeakLoadShifting(data) {
    return http.put('/ci-web/essConfig/setPeakLoadShifting', data, 'spin');
  },
  //设置限电功率支持
  setPowerLimitingSupport(data) {
    return http.put('/ci-web/essConfig/setPowerLimitingSupport', data, 'spin');
  },
  //获取安装记录
  getInstallerRecord(sn) {
    return http.get('/ci-web/deviceInstallInfo/getInstallRecord', { sn: sn });
  },
  //安装记录保存
  saveInstallerRecord(data) {
    return http.formSubmit(
      '/ci-web/deviceInstallInfo/installDeviceRecord',
      data,
      ''
    );
  },
  /**
   * @Author: Wayne Hu
   * @LastEditors: Wayne Hu
   * @description: 修改用户信息时，获取用户详细信息
   * @return {*}
   */
  getUserInfo() {
    return http.get('/ci-web/userInfo/getUserDetailInfo', {});
  },
  //用户信息的保存
  saveUserInfo(data) {
    return http.put('/ci-web/userInfo/updateUserInfo', data);
  },
  //获取设备列表
  getEquipmentList(params) {
    return http.get('/ci-web/deviceList/depot', params);
  },
  //获取国家列表
  getCountryList() {
    return http.get('/ci-web/dataDict/getKVList?dictType=country', {});
  },
  //获取国家列表
  getRegisterCountryList() {
    return http.get('/ci-base/user/getCountry', {});
  },
  // 获取场站类别
  getDepotType() {
    return http.get('/ci-web/dataDict/getKVList?dictType=depot_type', {});
  },
  // 场站运行数据- 运行数据
  getGridRegulationInfo(params) {
    return http.get('/ci-web/gridRegulation/getGridRegulationInfo', params);
  },
  //配置信息
  getGridRegulationSetting(params) {
    return http.get('/ci-web/gridRegulation/getGridRegulationSetting', params);
  },
  //配置信息保存
  saveGridRegulationSetting(data) {
    return http.post('/ci-web/gridRegulation/save', data);
  },
  //配置信息保存
  getFmParameters(data) {
    return http.get('/ci-web/setting/getFmParameters', data);
  },
  /**
   * @Author: YuKi
   * @description: 负载功率参数列表
   * @param {*} params
   * @return {*}
   */
  getLoadParaList(params) {
    return http.get('/ci-web/loadPara/list', params);
  },
  /**
   * @Author: YuKi
   * @description: 获取可添加设备类型
   * @param {*} data
   * @return {*}
   */
  getAllDevList(data) {
    return http.get('/ci-web/device/getAllDevList', data);
  },
  // 根据设备大类、子类、秒级数据标识获取jsonkey
  getDeviceJsonKey(data) {
    return http.get('/ci-web/device/getDeviceJsonKey', data);
  },
  // 根据场站id获取开关列表
  getBreaker(data) {
    return http.get('/ci-web/device/getBreaker', data);
  },
  /**
   * @Author: YuKi
   * @description:删除负载功率参数
   * @param {*} data
   * @return {*}
   */
  deleteDevice(data) {
    return http.delete('/ci-web/loadPara/delete', data);
  },

  /**
   * @Author: YuKi
   * @description: 请求负载功率参数
   * @param {Object} params 接口传参
   * @return {*}
   */
  requestList(params) {
    return http.post('/ci-web/loadPara/requestList', params);
  },
  /**
   * @Author: YuKi
   * @description: 请求负载功率参数
   * @param {Object} params 接口传参
   * @return {*}
   */
  resenttList(data) {
    return http.post('/ci-web/loadPara/reset', data);
  },
  /**
   * @Author: YuKi
   * @description: 新增负责功率参数
   * @param {*} data
   * @return {*}
   */
  addDevice(data) {
    return http.put('/ci-web/loadPara/add', data);
  },
  //获取基础参数
  getBasicParameters(key) {
    return http.get('/ci-web/setting/getBasicParameters', key);
  },
  //获取调度参数配置
  getSchedulingParameters(params) {
    return http.get('/ci-web/setting/getSchedulingParameters', params, 'spin');
  },
  //设置调度参数配置
  setSchedulingParameters(data) {
    return http.put('/ci-web/essConfig/setSchedulingParameters', data);
  },
  //设置基础参数配置
  setBasicParameters(data) {
    return http.put('/ci-web/essConfig/setBasicParameters', data);
  },
  //设置柴油机参数配置
  setDgConfig(data) {
    return http.put('/ci-web/essConfig/setDgConfig', data);
  },
  //设置自发自用模式
  setParallel(data) {
    return http.put('/ci-web/essConfig/setParallel', data, 'spin');
  },
  //设置SOC校准
  setSocCalibration(data) {
    return http.put('/ci-web/essConfig/setSocCalibration', data, 'spin');
  },
  // 获取安规列表
  getSafetyList(params) {
    return http.get('/ci-web/essConfig/getSafetyList', params);
  }
};
