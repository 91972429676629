/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:14:37
 */
import zh_registerPage from './zh-CN/translation.json';
import en_registerPage from './en-US/translation.json';
import de_registerPage from './de-DE/translation.json';

export const langRegister = {
  zh_registerPage,
  en_registerPage,
  de_registerPage
};
