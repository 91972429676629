/*
 * @Author: Summer.Chen
 * @Date:   2024-03-27 17:45:53
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2024-03-27 20:35:31
 */

export const ICToBMSKeyMapping = {
  'leftMenu.deviceOperatingData': 'leftMenu.batteryOperatingData',
  'leftMenu.singleBatteryData': 'leftMenu.batteryPackData'
};
