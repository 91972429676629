import { user } from '@/constants/actionType';
import {
  setUserInformation,
  removeUserInformation,
  removeLeftMenuList
} from '@/utils/token';
//reducer
export default function (state, action) {
  if (!state) {
    state = {
      token: '',
      name: 'admin',
      avatar: '',
      isAdmin: 0,
      permission: [],
      username: ''
    };
  }
  switch (action.type) {
    case user.LOGIN_SUCCESS:
      // 登录
      return { ...state, token: action.token };
    case user.GET_USERINFO_SUCCESS:
      // 获取用户信息
      try {
        setUserInformation(JSON.stringify(action));
      } catch (error) {}

      return {
        ...state,
        name: action.name,
        avatar: action.avatar,
        isAdmin: action.isAdmin,
        username: action.username
      };
    case user.LOGOUT_SUCCESS:
      removeUserInformation();
      removeLeftMenuList();
      // 登出
      return {
        token: state.token,
        name: '',
        avatar: '',
        isAdmin: 0,
        permission: []
      };
    default:
      return state;
  }
}
