/*
 * @Author: Summer.Chen
 * @Date:   2022-06-07 11:47:28
 * @Last Modified by:   Summer.Chen
 * @Last Modified time: 2023-07-12 19:13:16
 */
const theme = {
  light: {
    '@input-addon-bg': '@component-background',
    '@primary-color': '#FABF00',
    '@text-color_333333_opacity_80': 'rgba(51,51,51,.8)',
    '@color-opacity-black-25': 'rgba(0,0,0,.25)',
    '@text-opacity-color-white-80': 'rgba(255,255,255,.8)',
    '@border-color': '#323b40',
    '@header-color': '#fff',
    '@menu-text': '#b5b1b1',
    '@border-color-base': '#CECECE',
    '@color_3E3F43': '#3E3F43',
    '@item-active-bg': '#f7f8fa',
    '@component-background': '#F4F4F4',
    '@input-bg': '@component-background',
    '@color_989494': '#989494',
    '@input-placeholder-color': '@color_989494',
    '@input-border-color': '@border-color-base',
    '@heading-color': '#4F4F4F',
    '@label-color': '@heading-color',
    '@component-background-table': '#FFFFFF',
    '@table-bg': '@component-background-table',
    '@background-color-light': '#F7F8FA',
    '@text-color': '#333333',
    '@btn-primary-color': '#333333',
    '@table-border-color': '@border-color-base',
    '@card-background': '#fff',
    '@chart-bg-color': '#ffffff',
    '@chart-grid-line-color': '#CECECE',
    '@chart-line-color': '#CECECE',
    '@chart-legend-color': '#4F4F4F',
    '@chart-axias-text-color': '#8A8A8A',
    '@table-color': '#4F4F4F',
    '@layout-sider-background': '#fff',
    '@main-bg-color': '#F4F4F4',
    '@header-bg-color': '#ffffff',
    '@theme-color': '#4F4F4F',
    '@menu-bg': '#ffffff',
    '@menu-item-color': '@secretary-color',
    '@menu-item-active-bg': '#FFFDF8',
    '@menu-highlight-color': '@primary-color',
    '@menu-inline-submenu-bg': '#F5F5F5',
    '@radio-group-self-text-color': '@color_989494',
    '@radio-group-self-unline-color': '@primary-color',
    '@radio-button-active-color': '#4F4F4F',
    '@single-battery-chuan-bg': '#A3A3A3',
    '@single-battery-chuan-current': '#838383',
    '@single-battery-chuan-warning': '#FF4A55',
    '@secretary-color': '@color_989494',
    '@home-carousel-bg-color': '#fff',
    '@second-chart-bg-color': '#FBFBFB',
    '@scoll-track-color': '@color_989494',
    '@color_FBFBFB-opacity_8': 'rgba(251,251,251,.8)',
    '@bg-opacity-color': 'rgba(255, 255, 255, 0.75)',
    '@home-img-one': 'url(/static/svg/Capacity_Installed.svg)',
    '@home-img-two': 'url(/static/svg/Power_Installed.svg)',
    '@home-img-three': 'url(/static/svg/PV_Generation.svg)',
    '@home-img-four': 'url(/static/svg/Today_Charge.svg)',
    '@home-img-five': 'url(/static/svg/TOTAL_DISCHARGED.svg)',
    '@text-opacity-color': 'rgba(51,51,51,.8)',
    '@home-text-color': '@text-color_333333_opacity_80',
    '@home-chart-bg-color': '@color_FBFBFB-opacity_8',
    '@text-opacity-color-black_45': 'rgba(0, 0, 0, 0.45)',
    '@color_E2E2E2': '#E2E2E2',
    '@home-chart-circle-bg-color': '@color_E2E2E2',
    '@home-chart-circle-title-text-color': '@text-opacity-color-black_45',
    '@text-opacity-color-black_80': 'rgba(0,0,0,.8)',
    '@home-chart-circle-title-text-color-total': '@text-opacity-color-black_80',
    '@home-more-bg-color': '@color_FBFBFB-opacity_8',
    '@home-more-titlt-color': '@theme-color',
    '@home-data-text-color': '@text-opacity-color-black_80',
    '@collapsed-icon-color': '#1B1F3B',
    '@select-selection-item-bg': '#ffffff',
    '@home-baidu-map-bg-color': '#ffffff',
    '@home-baidu-map-text-color': '@text-color_333333_opacity_80',
    '@select-border-color': '@border-color-base',
    '@select-item-selected-color': '@text-color',
    '@select-dropdown-bg': '#ffffff',
    '@select-item-selected-bg': '',
    '@select-item-active-bg': '',
    '@select-background': '@component-background',
    '@btn-text-color': '#333333',
    '@baidu-map-w3': 'url(/static/images/w3.png)',
    '@chart-title-color': '#4F4F4F',
    '@table-header-color': '@theme-color',
    '@table-row-hover-bg': '@background-color-light',
    '@text-color-one': '@text-color',
    '@select-item': '@text-color_333333_opacity_80',
    '@tooltip-bg-color': '#FFFFFF',
    '@exporting-bg-color': '#ffffff',
    '@exporting-bg-hover-text-color': '@text-color_333333_opacity_80',
    '@exporting-bg-hover-bg-color': '@background-color-light',
    '@table-filter-dropdown-bg': '#ffffff',
    '@dropdown-menu-bg': '#ffffff',
    '@dropdown-selected-bg': 'transparent',
    '@select-arrow-color': '@color-opacity-black-25',
    '@single-battery-border-color': '#cecece',
    '@single-battery-li-last-bg-color': '@text-opacity-color-white-80',
    '@cluster-data-header-bg-color': '#f7f8fa',
    '@calendar-bg': '#ffffff',
    '@calendar-border-color': '#CECECE',
    '@picker-header-icon-color': '@color_989494',
    '@picker-header-view-botton-color': '@text-color_333333_opacity_80',
    '@pagination_total': '#323233',
    '@pagination-total-text-color': '@pagination_total',
    '@skeleton-color': 'rgba(190, 190, 190, 0.2)',
    '@card-head-color': '@theme-color',
    '@modal-content-bg': '@component-background',
    '@modal-header-bg': '@component-background',
    '@modal-heading-color': '@theme-color',
    '@modal-close-color': '@theme-color',
    '@modal-header-border-color-split': 'transparent',
    '@modal-table-td-border-color': '#CECECE',
    '@btn-default-color': '@theme-color',
    '@btn-default-bg': '@component-background',
    '@btn-default-border': '@border-color-base',
    '@popover-background': '@component-background',
    '@popover-bg': '@component-background',
    '@popover-color': '@theme-color',
    '@modal-footer-border-color-split': 'transparent',
    '@input-disabled-color': '@theme-color',
    '@collapse-header-bg': '#fafafa',
    '@table-header-cell-border-color': '#F7F8FA',
    '@table-header-cell-split-color': 'transparent',
    '@table-header-bg': '#F7F8FA',
    '@pagination-item-link': '@secretary-color',
    '@pagination_color': '#989494',
    '@pagination-item-link-color': '@pagination_color',
    '@tooltip-color-text-one': '@text-color_333333_opacity_80',
    '@collapse-header-border-color': '#d9d9d9',
    '@input-number-handler-bg': '@component-background',
    '@input-number-handler-border-color': '#CECECE',
    '@input-icon-color': '@theme-color',
    '@radio-disabled-button-checked-bg': '#f4f4f4',
    '@radio-disabled-button-checked-color': '#f4f4f4',
    '@radio-dot-disabled-color': '#cecece',
    '@radio-disabled-bg-color': '#f4f4f4',
    '@radio-disabled-border-color': '#CECECE',
    '@radio-disabled-text-color': '@theme-color',
    '@alert-message-color': '@theme-color',
    '@alert-text-color': '@theme-color',
    '@message-text-color': '@theme-color',
    '@chart-axis-text-color': '@color_989494',
    '@home-profit-text-color': '#8A8A8A',
    '@home-data-text-color-two': '#8A8A8A',
    '@text-color_333333_opacity_90': 'rgba(51,51,51,1)',
    '@bread-high-light-color': '@text-color_333333_opacity_90',
    '@bread-text-color': '@text-color_333333_opacity_80',
    '@tree-node-hover-bg': '#f5f5f5',
    '@electrical-flow-chart-flow': '/resource/mxgraph/shapes/12/11.png',
    '@electrical-flow-chart-tank': '/resource/mxgraph/shapes/12/10.png',
    '@electrical-flow-chart-pv': '/resource/mxgraph/shapes/12/9.png',
    '@electrical-flow-chart-otherLoad': '/resource/mxgraph/shapes/12/7.png',
    '@electrical-flow-chart-importantLoad': '/resource/mxgraph/shapes/12/6.png',
    '@electrical-flow-chart-fuse': '/resource/mxgraph/shapes/12/5.png',
    '@electrical-flow-chart-electricityMeter':
      '/resource/mxgraph/shapes/12/4.png',
    '@electrical-flow-chart-container': '/resource/mxgraph/shapes/12/3.png',
    '@electrical-flow-chart-powerGrid': '/resource/mxgraph/shapes/12/2.png',
    '@electrical-flow-chart-chargingPile': '/resource/mxgraph/shapes/12/1.png',
    '@electrical-flow-chart-battery': '/resource/mxgraph/shapes/12/0.png',
    '@mxgraph-bg-img':
      'url(/resource/mxgraph/images/mxgraph-content-light.svg)',
    '@mxgraph-select-border-color': '#CECECE',
    '@mxgraph-input-bg-color': '#F4F4F4',
    '@mxgraph-option-bg-color': '#ffffff',
    '@mxgraph-option-text-color': '@theme-color',
    '@mxgraph-option-hover-color': '#ffffff',
    '@mxgraph-select-text-color': '@theme-color',
    '@mxgraph-split-hover-color': '#e0e0e0',
    '@mxgraph-dialog-bg-color': 'rgba(0,0,0,.15)',
    '@mxgraph-grid-color': '#d0d0d0',
    '@color_E1E1E1': '#E1E1E1',
    '@select-disabled-bg': '@color_E1E1E1',
    '@input-disabled-bg': '@color_E1E1E1',
    '@production-title': '@table-color',
    '@card-grid-bg': '@radio-disabled-border-color',
    '@input-number-group-addon': '#F4F4F4',
    '@configuration-color': '#ffffff',
    '@table-selected': '#fffde6',
    '@confirm-color': '#000000',
    '@battery-img': '/static/svg/air-conditioner-light.svg',
    '@select-selection-item-border-color': '#ffffff',
    '@input-addon-bg': '#fafafa'
  },
  dark: {
    '@text-opacity-color-white-80': 'rgba(255,255,255,.8)',
    '@text-opacity-color-white-75': 'rgba(255,255,255,.75)',
    '@color_27282E': '#27282E',
    '@primary-color': '#FABF00',
    '@header-color': '#000000',
    '@color_989494': '#989494',
    '@menu-text': '#ff0000',
    '@text-color_333333_opacity_80': 'rgba(51,51,51,.8)',
    '@color_36373C': '#36373C',
    '@color-opacity-black-25': 'rgba(255,255,255,.25)',
    '@item-active-bg': '@color_27282E',
    '@color_3E3F43': '#3E3F43',
    '@border-color-base': '@color_27282E',
    '@border-color': '@border-color-base',
    '@component-background': '@color_27282E',
    '@input-bg': '@component-background',
    '@input-placeholder-color': '@color_989494',
    '@input-border-color': '@border-color-base',
    '@heading-color': '#4F4F4F',
    '@component-background-table': '@color_36373C',
    '@table-bg': '@component-background-table',
    '@background-color-light': '#F7F8FA',
    '@text-color': '#333333',
    '@btn-primary-color': '#ffffff',
    '@table-border-color': '#5A5A5A',
    '@card-background': '@color_36373C',
    '@chart-bg-color': 'transparent',
    '@chart-grid-line-color': '#5A5A5A',
    '@chart-line-color': '#EBEBEB',
    '@chart-legend-color': '@text-opacity-color-white-80',
    '@label-color': '@text-opacity-color-white-80',
    '@chart-axias-text-color': '#8A8A8A',
    '@chart-title-color': '@color_989494',
    '@table-color': '@text-opacity-color-white-80',
    '@main-bg-color': '@color_27282E',
    '@header-bg-color': '@color_36373C',
    '@theme-color': '@text-opacity-color-white-80',
    '@menu-bg': '@color_36373C',
    '@menu-item-color': '@secretary-color',
    '@menu-item-active-bg': '@color_27282E',
    '@menu-highlight-color': '@primary-color',
    '@menu-inline-submenu-bg': '@color_27282E',
    '@radio-group-self-text-color': '@color_989494',
    '@radio-group-self-unline-color': '@primary-color',
    '@radio-button-active-color': '@text-opacity-color-white-80',
    '@single-battery-chuan-bg': '#6B6B6B',
    '@single-battery-chuan-current': '#838383',
    '@single-battery-chuan-warning': '#FF4A55',
    '@secretary-color': '#8A8A8A',
    '@home-carousel-bg-color': '#fff',
    '@second-chart-bg-color': 'transparent',
    '@table-header-color': '@text-opacity-color-white-80',
    '@layout-sider-background': '@menu-bg',
    '@scoll-track-color': '#4F4F4F',
    '@bg-opacity-color': 'rgba(54,55,60, 0.75)',
    '@home-img-one': 'url(/static/svg/dark/Capacity_Installed.svg)',
    '@home-img-two': 'url(/static/svg/dark/Power_Installed.svg)',
    '@home-img-three': 'url(/static/svg/dark/PV_Generation.svg)',
    '@home-img-four': 'url(/static/svg/dark/Today_Charge.svg)',
    '@home-img-five': 'url(/static/svg/dark/TOTAL_DISCHARGED.svg)',
    '@text-opacity-color': 'rgba(255,255,255,1)',
    '@home-text-color': '@text-opacity-color-white-80',
    '@home-chart-bg-color': 'transparent',
    '@home-chart-circle-bg-color': '@color_4F4F4F',
    '@home-chart-circle-title-text-color': '#FFFFFF',
    '@home-chart-circle-title-text-color-total': '#ffffff',
    '@home-more-bg-color': 'rgba(62, 63, 67, 0.8)',
    '@home-more-titlt-color': '@text-opacity-color-white-80',
    '@home-more-btn-text-color': '@primary-color',
    '@home-more-btn-bg-color': 'rgba(248, 182, 0, 0.1)',
    '@home-more-btn-border-color': 'transparent',
    '@home-data-text-color': '#F4F4F4',
    '@collapsed-icon-color': '#ffffff',
    '@home-baidu-map-bg-color': '@color_3E3F43',
    '@home-baidu-map-text-color': '@text-opacity-color-white-80',
    '@select-border-color': '@border-color-base',
    '@select-item-selected-color': '@text-opacity-color-white-80',
    '@select-dropdown-bg': '@color_3E3F43',
    '@select-item-selected-bg': '@color_27282E',
    '@select-selection-item-bg': '@color_27282E',
    '@select-item-active-bg': '@item-active-bg',
    '@select-background': '@component-background',
    '@btn-text-color': '#333333',
    '@baidu-map-w3': 'url(/static/images/w3-dark.png)',
    '@table-row-hover-bg': '@color_27282E',
    '@text-color-one': '@color_989494',
    '@select-item': '@color_989494',
    '@tooltip-bg-color': '@color_3E3F43',
    '@tooltip-color-text-one': '@text-opacity-color-white-80',
    '@exporting-bg-color': '@color_3E3F43',
    '@exporting-bg-hover-text-color': '@text-opacity-color-white-80',
    '@exporting-bg-hover-bg-color': '@color_27282E',
    '@table-filter-dropdown-bg': '@color_3E3F43',
    '@dropdown-menu-bg': '@color_3E3F43',
    '@dropdown-selected-bg': 'transparent',
    '@select-arrow-color': '@color_989494',
    '@single-battery-border-color': '#cecece',
    '@single-battery-li-last-bg-color': '@color_3E3F43',
    '@cluster-data-header-bg-color': '@header-bg-color',
    '@calendar-bg': '@color_3E3F43',
    '@calendar-border-color': '#CECECE',
    '@picker-header-icon-color': '@color_989494',
    '@picker-header-view-botton-color': '@text-opacity-color-white-80',
    '@pagination_total': 'rgba(255, 255, 255, 0.8)',
    '@pagination-total-text-color': '@pagination_total',
    '@skeleton-color': '@color_36373C',
    '@card-head-color': '@theme-color',
    '@modal-content-bg': '@color_36373C',
    '@modal-header-bg': '@color_36373C',
    '@modal-heading-color': '@theme-color',
    '@modal-close-color': '@theme-color',
    '@modal-header-border-color-split': 'transparent',
    '@modal-table-td-border-color': '#5A5A5A',
    '@btn-default-color': '@theme-color',
    '@btn-default-bg': '@color_3E3F43',
    '@btn-default-border': '@theme-color',
    '@popover-background': '@color_3E3F43',
    '@popover-bg': '@color_3E3F43',
    '@popover-color': '@theme-color',
    '@modal-footer-border-color-split': 'transparent',
    '@input-disabled-bg': '@component-background',
    '@input-disabled-color': '@color_989494',
    '@collapse-header-bg': '@color_36373C',
    '@table-header-cell-border-color': '#CECECE',
    '@table-header-cell-split-color': 'transparent',
    '@table-header-bg': '@header-bg-color',
    '@pagination-item-link': '@secretary-color',
    '@pagination_color': '#989494',
    '@pagination-item-link-color': '@pagination_color',
    '@collapse-header-border-color': '#5A5A5A',
    '@input-number-handler-bg': '#8A8A8A',
    '@input-number-handler-border-color': '#5A5A5A',
    '@input-icon-color': '@theme-color',
    '@radio-disabled-button-checked-bg': '#f4f4f4',
    '@radio-disabled-button-checked-color': '@theme-color',
    '@radio-dot-disabled-color': '@theme-color',
    '@radio-disabled-bg-color': '#dddddd',
    '@radio-disabled-border-color': '#CECECE',
    '@radio-disabled-text-color': '@theme-color',
    '@alert-message-color': '@theme-color',
    '@alert-text-color': '@theme-color',
    '@message-text-color': '@theme-color',
    '@chart-axis-text-color': '@color_989494',
    '@home-profit-text-color': '@text-opacity-color-white-75',
    '@home-data-text-color-two': '@text-opacity-color-white-80',
    '@text-opacity-color-white-90': 'rgba(255,255,255,1)',
    '@bread-high-light-color': '@text-opacity-color-white-90',
    '@bread-text-color': '@text-opacity-color-white-80',
    '@tree-node-hover-bg': '@color_36373C',
    '@electrical-flow-chart-flow': '/resource/mxgraph/shapes/12/dark/11.png',
    '@electrical-flow-chart-tank': '/resource/mxgraph/shapes/12/dark/10.png',
    '@electrical-flow-chart-pv': '/resource/mxgraph/shapes/12/dark/9.png',
    '@electrical-flow-chart-otherLoad':
      '/resource/mxgraph/shapes/12/dark/7.png',
    '@electrical-flow-chart-importantLoad':
      '/resource/mxgraph/shapes/12/dark/6.png',
    '@electrical-flow-chart-fuse': '/resource/mxgraph/shapes/12/dark/5.png',
    '@electrical-flow-chart-electricityMeter':
      '/resource/mxgraph/shapes/12/dark/4.png',
    '@electrical-flow-chart-container':
      '/resource/mxgraph/shapes/12/dark/3.png',
    '@electrical-flow-chart-powerGrid':
      '/resource/mxgraph/shapes/12/dark/2.png',
    '@electrical-flow-chart-chargingPile':
      '/resource/mxgraph/shapes/12/dark/1.png',
    '@electrical-flow-chart-battery': '/resource/mxgraph/shapes/12/dark/0.png',
    '@mxgraph-bg-img': 'url(/resource/mxgraph/images/mxgraph-content-dark.svg)',
    '@mxgraph-select-border-color': 'transparent',
    '@mxgraph-input-bg-color': '#27282E',
    '@mxgraph-option-bg-color': '#ffffff',
    '@mxgraph-option-text-color': '#ffffff',
    '@mxgraph-option-hover-color': '@color_36373C',
    '@mxgraph-select-text-color': '@theme-color',
    '@mxgraph-split-hover-color': '#5A5A5A',
    '@select-selection-item-border-color': '#5A5A5A',
    '@mxgraph-dialog-bg-color': 'rgba(0,0,0,.4)',
    '@mxgraph-grid-color': '#f1f1f1',
    '@color_E1E1E1': '#27282E',
    '@select-disabled-bg': '@color_E1E1E1',
    '@card-grid-bg': '#5A5A5A',
    '@production-title': '@table-color',
    '@input-number-group-addon': '#27282E',
    '@configuration-color': '#5c5c5c',
    '@ant-select-dropdown': '#52c41a',
    '@table-selected': '#27282E',
    '@confirm-color': '#ffffff',
    '@battery-img': '/static/svg/dark/dark-air-conditioner.svg',
    '@input-addon-bg': '@component-background'
  }
};
export { theme };
