/*
 * @Description: 语言列表
 * @Author: April
 * @Date: 2023-09-20 11:15:02
 * @LastEditors: April
 * @LastEditTime: 2023-09-20 11:17:50
 */
export const languageList = [
  {
    value: 'zh-CN',
    label: '中文',
    code: 'zh'
  },
  {
    value: 'en-US',
    label: 'English',
    code: 'en'
  },
  {
    value: 'de-DE',
    label: 'Deutsch',
    code: 'de'
  }
];
