// action creators
import { user } from '@/constants/actionType';

export const login = token => {
  return { type: user.LOGIN_SUCCESS, token: token };
};

export const updateUserInfo = userInfo => {
  return { type: user.GET_USERINFO_SUCCESS, ...userInfo };
};

export const logout = () => {
  return { type: user.LOGOUT_SUCCESS };
};
