/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:21:59
 * @LastEditTime: 2023/12/25 19:11:03
 */
import zh_backStage from './zh-CN/translation.json';
import en_backStage from './en-US/translation.json';
import de_backStage from './de-DE/translation.json';

export const langBackStage = {
  zh_backStage,
  en_backStage,
  de_backStage
};
