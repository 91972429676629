import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Button, Select, Input, message, Checkbox } from 'antd';
import { connect } from 'react-redux';
import LangSelect from '@/components/LangSelect';
import '@/style/login.less';
import beforeLogin from '@/services/beforeLogin.js';
import auth from 'auth';
import { theme } from '@/theme';
import { InfoCircleOutlined } from '@ant-design/icons';
import { encrypt } from '@/constants/common';
import { timeZoneList } from '@/constants/timeZone';
const { Option } = Select;
@withTranslation(['leftMenu', 'registerPage'])
// 注册页面对应的组件
class Register extends React.PureComponent {
  constructor(props) {
    super(props);
    this.registerForm = React.createRef(); // 创建注册表单的引用
    this.state = {
      // 国家列表
      countryList: [],
      isDisabled: false,
      time: 60, // 规定起始时间60s
      userType: 'customer'
    };
  }

  curtomerHeight() {
    document.querySelector('.curtomer-style-register').style.height =
      window.innerHeight;
  }
  // 提交注册表单
  submitRegisterForm = values => {
    const { t } = this.props;
    // delete values.confirmPassword;
    for (let key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    values.password = encrypt(values.password.trim(), values.userName.trim());
    values.confirmPassword = encrypt(
      values.confirmPassword.trim(),
      values.userName.trim()
    );
    this.setState({
      isDisabled: true
    });
    beforeLogin
      .register({
        ...values,
        languageCode: this.props.language == 'zh' ? 'zh-CN' : 'en-US'
      })
      .then(res => {
        if (res && res.code === 200) {
          message.success(t('registerPage:registerPage.registeSuccess'));
          this.changeRoute();
        }
        this.setState({
          isDisabled: false
        });
      })
      .catch(error => {
        this.setState({
          isDisabled: false
        });
      });
  };
  // 取消注册
  changeRoute = () => {
    this.props.history.push('/login');
  };
  // 获取国家列表
  getCountryList = () => {
    auth
      .getRegisterCountryList()
      .then(res => {
        if (res && res.code === 200 && res.data) {
          this.setState({ countryList: [...res.data] });
        } else {
          this.setState({ countryList: [] });
        }
      })
      .catch(() => {
        this.setState({ countryList: [] });
      });
  };
  // 注册发送邮件
  sendRegisterEmail = callback => {
    const { i18n, t } = this.props;
    const username = this.registerForm.current.getFieldValue('username');
    const language = i18n.language === 'zh' ? 'zh-CN' : 'en-US';
    beforeLogin.sendRegisterEmail(username, language).then(ret => {
      if (ret && ret.code === 200) {
        message.success(t('registerPage:registerPage.verificationCodeSuccess'));
        if (typeof callback == 'function') {
          callback();
        }
      }
    });
  };
  // 点击验证码触发计时器
  getCode = () => {
    const { t } = this.props;
    const self = this;
    let userName = this.registerForm.current.getFieldValue('userName');
    if (!userName) {
      message.error(t('registerPage:registerPage.userNameRequired'));
      return;
    }
    this.registerForm.current.validateFields(['userName']).then(values => {
      self.sendRegisterEmail(function () {
        setTimeout(() => self.onGetCode(), 0);
      });
    });
  };
  onGetCode = () => {
    let { time } = this.state;
    if (time > 0) {
      --time;
      this.setState({
        time
      });
      setTimeout(() => this.onGetCode(), 1000); //一秒执行后如果时间没到会再执行一次
    } else {
      this.setState({
        time: 60
      });
    }
  };
  // 组件挂载前
  UNSAFE_componentWillMount() {
    this.getCountryList();
  }
  componentDidMount() {
    document.getElementsByClassName('register_mainBox')[0].style.background =
      "url('/static/images/login_bg.png') center center / cover no-repeat";
    this.curtomerHeight();
    let model = 'light';
    window.less.modifyVars({
      ...theme[model]
    });
  }
  /**
   * @description: 获取用户类别
   * @return {*}
   * @LastEditors: Victor
   */
  getUserType = val => {
    this.setState({
      userType: val
    });
  };
  render() {
    let { t } = this.props;
    const addressTip = (
      <span style={{ color: '#555555' }}>
        {t('registerPage:registerPage.addressTips')}
      </span>
    );
    const cellPhoneTip = (
      <span style={{ color: '#555555' }}>
        {t('registerPage:registerPage.cellPhoneTips')}
      </span>
    );

    return (
      <div className="curtomer-style-register register_mainBox">
        <div className="register_main">
          {/*公司logo */}
          <div className="logo flex">
            <span className="logo-content">
              <img alt="logo" src="/static/images/alpha-logo.png" />
            </span>
            <div className="switch-language">
              <LangSelect />
            </div>
          </div>
          <div className="register_body">
            <div className="register_login">
              <span className="register_text_Account">
                {t('registerPage:registerPage.AlreadyHaveAnAccount')}{' '}
              </span>
              <a className="register_text_login" href="/#/Login">
                {t('registerPage:registerPage.LogInNow')}
              </a>
            </div>

            <div className="register_bg">
              <h1 className="register_title">
                {' '}
                {t('registerPage:registerPage.registerTitle')}
              </h1>

              <Form
                className="register_form"
                ref={this.registerForm}
                id="normal_login"
                layout="vertical"
                onFinish={this.submitRegisterForm}
                initialValues={{
                  userName: '',
                  code: '',
                  postcode: '',
                  cellphone: '',
                  confirmPassword: '',
                  timezone: '',
                  password: '',
                  cityCode: '',
                  linkman: '',
                  stateCode: '',
                  countryId: '',
                  userType: 'customer',
                  sysSn: '',
                  snCheckCode: '',
                  licenseNo: '',
                  address: '',
                  languageCode: 'zh-CN'
                }}
              >
                <Row gutter={8}>
                  {/* 用户类别 */}
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t('registerPage:registerPage.userType')}
                      name="userType"
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: true,
                          message: t('registerPage:registerPage.usertype')
                        }
                      ]}
                    >
                      <Select onChange={this.getUserType}>
                        <Option value={'customer'}>
                          {t('registerPage:registerPage.usertype_customer')}
                        </Option>
                        <Option value={'installer'}>
                          {t('registerPage:registerPage.usertype_installer')}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* 系统SN */}
                  {this.state.userType === 'customer' ? (
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                      <Form.Item
                        label={t('registerPage:registerPage.systemSN')}
                        name="sysSn"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: t('registerPage:registerPage.snIsRequired')
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}
                  {/* SN校验码 */}
                  {this.state.userType === 'customer' ? (
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                      <Form.Item
                        label={t('registerPage:registerPage.snCheckCode')}
                        name="snCheckCode"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'registerPage:registerPage.snCheckRequired'
                            )
                          }
                        ]}
                      >
                        <Input maxLength={64} />
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}
                  {/* License */}
                  {this.state.userType === 'installer' ? (
                    <Col xs={24} sm={8} md={8} lg={8} xl={16}>
                      <Form.Item
                        label={t('registerPage:registerPage.license')}
                        name="licenseNo"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'registerPage:registerPage.licenseIsRequired'
                            )
                          }
                        ]}
                      >
                        <Input maxLength={64} />
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}
                  {/* 用户名 */}
                  <Col xs={24} sm={8} md={8} lg={8} xl={12}>
                    <Form.Item
                      label={t('registerPage:registerPage.userName')}
                      name="userName"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.userNameRequired'
                          )
                        },
                        {
                          pattern:
                            /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z0-9_\-\+]{2,8})$/,
                          message: t(
                            'registerPage:registerPage.userFormatLimit'
                          )
                        }
                      ]}
                    >
                      <Input maxLength={64} />
                    </Form.Item>
                  </Col>
                </Row>
                {/* 用户密码和确认密码 */}
                <Row gutter={8}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={t('registerPage:registerPage.password')}
                      name="password"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.passwordIsRequired'
                          )
                        },

                        {
                          validator: (rule, value) => {
                            if (typeof value === 'string') {
                              value = value.trim();
                              if (value.length === 0) {
                                return Promise.reject(
                                  t(
                                    'registerPage:registerPage.passwordNotFullNull'
                                  )
                                );
                              }
                            }

                            if (value.length >= 4 && value.length <= 20) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                t('registerPage:registerPage.passwordLimit')
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Input type="password" maxLength={20} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={t('registerPage:registerPage.confirmPwd')}
                      name="confirmPassword"
                      dependencies={['password']}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.confirmPwdIsRequired'
                          )
                        },

                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (typeof value === 'string') {
                              value = value.trim();
                            }
                            if (
                              !value ||
                              getFieldValue('password').trim() === value.trim()
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              t(
                                'registerPage:registerPage.confirmPwdisInconsistent'
                              )
                            );
                          }
                        })
                      ]}
                    >
                      <Input type="password" maxLength={20} />
                    </Form.Item>
                  </Col>
                </Row>

                {/* 国家/省州/城市 */}
                <Row gutter={8}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t('registerPage:registerPage.country')}
                      name="countryId"
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.countryIsRequired'
                          )
                        }
                      ]}
                    >
                      <Select>
                        {this.state.countryList.map(item => (
                          <Option key={item.areaId} value={item.areaId}>
                            {item.areaEnglishName +
                              '[' +
                              item.areaFirstName +
                              ']'}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t('registerPage:registerPage.state')}
                      name="stateCode"
                    >
                      <Input maxLength={64} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t('registerPage:registerPage.city')}
                      name="cityCode"
                    >
                      <Input maxLength={64} />
                    </Form.Item>
                  </Col>
                </Row>

                {/* 详细地址和邮编 */}
                <Row gutter={8}>
                  <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={t('registerPage:registerPage.detailAddress')}
                      name="address"
                      tooltip={{
                        title: addressTip,
                        icon: <InfoCircleOutlined />,
                        placement: 'right',
                        color: '#ffffff'
                      }}
                    >
                      <Input maxLength={64} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t('registerPage:registerPage.postCode')}
                      name="postcode"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.postCodeIsRequired'
                          )
                        },
                        {
                          validator: (rule, value) => {
                            if (typeof value === 'string') {
                              value = value.trim();
                            }
                            if (value.length >= 4 && value.length <= 16) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                t('registerPage:registerPage.postCodeLimit')
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                {/* 语言,联系人,电话 */}
                <Row gutter={8}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={t('registerPage:registerPage.connectPerson')}
                      name="linkman"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.connectPersonIsRequired'
                          )
                        }
                      ]}
                    >
                      <Input maxLength={64} />
                    </Form.Item>
                  </Col>
                  {/* 联系电话 */}
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={t('registerPage:registerPage.contactNumber')}
                      name="cellphone"
                      validateTrigger="onBlur"
                      tooltip={{
                        title: cellPhoneTip,
                        icon: <InfoCircleOutlined />,
                        placement: 'right',
                        color: '#ffffff'
                      }}
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.contactNumberIsRequired'
                          )
                        },

                        {
                          validator: (rule, value) => {
                            if (typeof value === 'string') {
                              value = value.trim();
                            }

                            if (value.length >= 6 && value.length <= 20) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                t(
                                  'registerPage:registerPage.contactNumberLimit'
                                )
                              );
                            }
                          }
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  {/* 时区 */}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label={t('registerPage:registerPage.timeZone')}
                      name="timezone"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.timeZoneRequired'
                          )
                        }
                      ]}
                    >
                      <Select>
                        {timeZoneList.map(item => (
                          <Option
                            key={item.DisplayName}
                            value={item.StandardName}
                          >
                            {item.DisplayName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {/* 联系我们 和 隐私政策 */}
                <Row gutter={8}>
                  {/* 隐私条款 */}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="primaryPolicy"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    `${t(
                                      'registerPage:registerPage.pleaseCheckPrivacyPolicy'
                                    )}`
                                  )
                                )
                        }
                      ]}
                    >
                      <Checkbox className="checked-style">
                        <div>
                          {t('registerPage:registerPage.AgreeTerms')}
                          <a
                            href="https://www.alphaess.com/privacy-policy"
                            target="_blank"
                          >
                            《{t('registerPage:registerPage.PrivacyPolicy')}》
                          </a>
                        </div>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                {/* 注册和取消按钮 */}
                <Row gutter={8}>
                  <Form.Item className="register_box">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={this.state.isDisabled}
                      className="register_btn btn-radius_25 btn_white"
                    >
                      {t('registerPage:registerPage.submit')}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    name: state.user.name,
    language: state.app.language
  };
};

export default withRouter(connect(mapStateToProps)(Register));
