/*
 * @Description:
 * @Author: Summer.Chen
 * @Date: 2023-11-14 14:58:09
 * @LastEditTime: 2023/12/25 19:08:58
 * @LastEditors: Wayne Hu
 */
import zh_bms from './zh-CN/translation.json';
import en_bms from './en-US/translation.json';
import de_bms from './de-DE/translation.json';

export const bms = {
  zh_bms,
  en_bms,
  de_bms
};
