/*
 * @Description:
 * @Author: April
 * @Date: 2022-11-24 14:40:34
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2023/12/26 19:46:30
 */
import http from '@/utils/http';
export default {
  // 注册接口
  register(data) {
    return http.post('/ci-base/user/register', data);
  },
  // 忘记密码
  forgetPwd(data) {
    return http.post('/ci-base/userInfo/forgetPassword', data);
  },
  // 找回用户名
  forgetUsername(data) {
    return http.post('/ci-web/userInfo/forgetUsername', data);
  },
  // 修改密码
  changePwd(data) {
    return http.put('/ci-base/account/editPassword', data);
  },
  // 提交--忘记密码,重置密码
  resetPwd(data) {
    return http.post('/ci-base/user/resetPassword', data);
  },
  // 获取验证码--忘记密码，发送邮件
  sendRegisterEmail(data) {
    return http.post('/ci-base/user/forgetPassword', data);
  },
  // 获取验证码--忘记密码，发送邮件
  sendResetPasswordEmail(data) {
    return http.post('/ci-base/user/forgetPassword', data);
  },
  // 下一步--忘记密码,校验验证码
  confirmForgetPasswordCode(data) {
    return http.post('/ci-base/user/confirmCodeForForgetPassword', data);
  },
  // 重置密码发送邮件
  bindRole(data) {
    return http.post('/ci-web/guide/bind', data);
  },
  // 激活用户注册的账号
  activeAccount(data) {
    return http.post('/ci-base/user/sendRegisterActivation', data);
  }
};
