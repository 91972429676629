/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:13:38
 */
import en_leftMenu from './en-US/translation.json';
import zh_leftMenu from './zh-CN/translation.json';
import de_leftMenu from './de-DE/translation.json';

export const langLeftMenu = {
  en_leftMenu,
  zh_leftMenu,
  de_leftMenu
};
