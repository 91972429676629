/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:12:14
 */
import zh_codeValue from './zh-CN/translation.json';
import en_codeValue from './en-US/translation.json';
import de_codeValue from './de-DE/translation.json';

export const langCodeValue = {
  zh_codeValue,
  en_codeValue,
  de_codeValue
};
