import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import deDE from 'antd/es/locale/de_DE';
let util = {};
util.title = function (title) {
  title = title || 'AlphaESS';
  window.document.title = title;
};

util.timeChange = function (min) {
  let str = '';
  let now = moment().format('YYYY-MM-DD');
  if (min !== null) {
    let hour = parseInt(min / 60);
    let minute = min % 60;
    str =
      now +
      ' ' +
      (hour < 10 ? '0' + hour : hour.toString()) +
      ':' +
      (minute < 10 ? '0' + minute : minute.toString()) +
      ':00';
    str = moment(str);
  }
  return str;
};

util.getMenuByName = function (name, menulist) {
  let menu = {};
  let forFn = function (name, menulist) {
    for (var item of menulist) {
      if (item.name === name) {
        menu = item;
      } else {
        if (item.children && item.children.length > 0) {
          forFn(name, item.children);
        }
      }
      if (menu.name) {
        break;
      }
    }
  };
  forFn(name, menulist);
  return menu;
};

util.handleMenu = function innerHandle(arrTree, level) {
  const res = [];
  let innerLevel = level;
  if (arrTree === '' || arrTree.length === 0) {
    return [];
  }
  arrTree.forEach(route => {
    const tmp = { ...route };
    if (innerLevel === 1 && !tmp.parentId) {
      const routeItem = {
        parentId: '',
        path: tmp.menuUrl,
        name: tmp.menuUrlName,
        leftMenu: tmp.isDisplay === 1 ? true : false,
        icon: tmp.icon,
        id: tmp.id,
        languageKey: tmp.languageKey,
        privilegeType: tmp.menuType === 0 || tmp.menuType === 1 ? 1 : 3,
        title: tmp.menuTitle,
        privilegeName: tmp.menuTitle,
        children:
          tmp.children && tmp.children.length > 0
            ? innerHandle(tmp.children, innerLevel + 1)
            : []
      };
      res.push(routeItem);
    } else {
      const routeItem = {
        parentId: tmp.parentId,
        path: tmp.menuUrl,
        name: tmp.menuUrlName,
        leftMenu:
          tmp.isDisplay === 1 &&
          tmp.languageKey !== 'leftMenu.equipmentFailureLog' &&
          tmp.languageKey !== 'leftMenu.PCSRemoteparametersetting'
            ? true
            : false,
        icon: tmp.icon,
        id: tmp.id,
        languageKey: tmp.languageKey,
        privilegeType: tmp.menuType === 0 || tmp.menuType === 1 ? 1 : 3,
        title: tmp.menuTitle,
        privilegeName: tmp.menuTitle,
        children:
          tmp.children && tmp.children.length > 0
            ? innerHandle(tmp.children, innerLevel + 1)
            : []
      };
      res.push(routeItem);
    }
  });
  return res;
};

util.getTreeEleByPropertyValue = function (value, property, list) {
  let ele = {};
  let forFn = function (value, property, list) {
    for (var item of list) {
      if (item[property] === value) {
        ele = item;
      } else {
        if (item.children && item.children.length > 0) {
          forFn(value, property, item.children);
        }
      }
      if (ele[property]) {
        break;
      }
    }
  };
  forFn(value, property, list);
  return ele;
};

util.oneOf = function (ele, targetArr) {
  if (targetArr.indexOf(ele) >= 0) {
    return true;
  } else {
    return false;
  }
};
util.getParentMenusByName = function (openAccesseMenu, name) {
  let temp = [];
  let forFn = function (openAccesseMenu, name) {
    for (var item of openAccesseMenu) {
      if (item.name === name) {
        temp.push(item);
        forFn(openAccesseMenu, item.parentName);
      }
    }
  };
  forFn(openAccesseMenu, name);
  temp.reverse();
  return temp;
};

util.getTreeEleWithParent = function (id, list) {
  let temp = [];
  let forFn = function (id, list) {
    for (var item of list) {
      if (item.id === id) {
        let newItem = { ...item };
        temp.push(newItem);
        forFn(item.parentId, list);
      }
    }
  };
  forFn(id, list);
  temp.reverse();
  return temp;
};

util.handleTitle = function (vm, item) {
  return item.title;
};

util.openAccesseMenu = function (accesseMenu) {
  let openAccesseMenu = [];

  let forFn = function (menulist, parentName) {
    for (var item of menulist) {
      item.parentName = parentName;
      openAccesseMenu.push(item);
      if (item.children && item.children.length > 0) {
        forFn(item.children, item.name);
      }
    }
  };
  forFn(accesseMenu, '');
  return openAccesseMenu;
};

util.openTreeData = data => {
  let openAccesseMenu = [];
  let forFn = function (data) {
    for (var item of data) {
      openAccesseMenu.push({ ...item });
      if (item.children && item.children.length > 0) {
        forFn(item.children);
      }
    }
  };
  forFn(data);
  return openAccesseMenu;
};
util.getUrlParamValue = (search, params) => {
  let tempObj = {};
  if (typeof params === 'string') {
    let match = RegExp(`[?&]${params}=([^&]*)`) //分组运算符是为了把结果存到exec函数返回的结果里
      .exec(search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' ')); // url中+号表示空格,要替换掉
  } else if (params instanceof Array) {
    for (let key of params) {
      let match = RegExp(`[?&]${key}=([^&]*)`) //分组运算符是为了把结果存到exec函数返回的结果里
        .exec(search);
      tempObj[key] = match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
    return tempObj;
  }
};

// 限制输入内容必须是数值
util.limitNumber = value => {
  if (typeof value == 'number') {
    value = value.toString();
  }
  let valueFormat = value.replace(/[^0-9.]/g, ''); //清除“数字”和“.”以外的字符
  valueFormat = valueFormat.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
  valueFormat = valueFormat
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.');
  valueFormat = valueFormat.replace(/^-*(\d+)\.(\d\d).*$/, '$1$2.$3');
  //只能输入两位小数
  if (valueFormat.indexOf('.') < 0 && valueFormat !== '') {
    return parseFloat(valueFormat);
  }

  return valueFormat;
};
// 全局配置环境地址
util.environmentAddress = () => {
  let baseUrl = '';
  if (process.env.NODE_ENV === 'development') {
    baseUrl = 'http://192.168.8.34:8002/fileStorage';
  } else if (process.env.NODE_ENV === 'production') {
    baseUrl = '/fileStorage';
  }
  return baseUrl;
};

/**
 * @description: 转换语言拼接后缀
 * @param {*} language
 * @return {*}
 * @LastEditors: Victor
 */
util.convertLangage = () => {
  let getLang = localStorage.getItem('language') || navigator.language; // 获取浏览器的语言环境，兼容IE的写法
  let result = '';
  switch (getLang) {
    case 'zh':
      result = 'zh-CN';
      break;
    case 'en':
      result = 'en-US';
      break;
    case 'de':
      result = 'de-DE';
      break;
    default:
      result = 'zh-CN';
  }
  return result;
};
util.convertLangageAntd = Lang => {
  let result = Lang;
  switch (Lang) {
    case 'zh':
      result = zhCN;
      break;
    case 'en':
      result = enUS;
      break;
    case 'de':
      result = deDE;
      break;
    default:
      result = enUS;
  }
  return result;
};
//获取Url 指定参数的值
util.getParamsValue = (varNme, location) => {
  let reg = new RegExp('(^|&)' + varNme + '=([^&]*)(&|$)', 'i');
  let r = location
    ? location.search.substr(1).match(reg)
    : window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};
util.getPreAPI = () => {
  return 'api';
};
export default util;
