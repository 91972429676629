import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from '@/i18n/resources';
import Backend from 'i18next-xhr-backend';
function initLangage() {
  let lang =
    localStorage.getItem('language') ||
    navigator.language ||
    navigator.userLanguage; // 获取浏览器的语言环境，兼容IE的写法
  if (lang) {
    lang = lang.substr(0, 2).toLowerCase(); // 截取前两位字符，并转化为小写
    return lang;
  } else {
    return 'en';
  }
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    resources,
    lng: initLangage(),
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false // this will do the magic
    }
  });
export default i18n;
