import React from 'react';
import { withTranslation } from 'react-i18next'; // 国际化
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Form, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import LangSelect from '@/components/LangSelect'; // 切换语言
import variables from '@/style/styles/variables.scss';
import beforeLogin from '@/services/beforeLogin.js';

const { Header } = Layout;
@withTranslation(['leftMenu', 'beforeLogin'])

// 重置密码页面
class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.resetPasswordForm = React.createRef(); // 忘记密码表单
    this.state = {};
  }
  // 获取URL参数
  getQueryVariable = variable => {
    let queryString = this.props.location.search.substring(1);
    let queryArr = queryString.split('&');
    for (let i = 0; i < queryArr.length; i++) {
      var paraArr = queryArr[i].split('=');
      if (paraArr[0] === variable) {
        return paraArr[1];
      }
    }
    return false;
  };

  // 重置密码
  resetPassword = values => {
    const { t } = this.props;
    for (let key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    delete values.confirmPwd;
    let userIdString = this.getQueryVariable('userId');
    if (userIdString !== false) {
      let params = { ...values, userId: userIdString };
      beforeLogin
        .resetPwd(params)
        .then(res => {
          if (res.code === 200) {
            message.success(t('beforeLogin:resetPassword.resetPwdSuccess'));
            this.changeRoute(); // 跳转至登录页面
          }
        })
        .catch(error => {});
    } else {
      message.success(t('beforeLogin:resetPassword.userIdIsNull'));
    }
  };
  // 取消重置密码
  changeRoute = () => {
    this.props.history.push('/login');
  };
  // 组件挂载前
  UNSAFE_componentWillMount() {
    document.getElementsByTagName('body')[0].style.backgroundColor =
      'rgb(16, 34, 54)';
  }
  render() {
    let { t } = this.props;
    return (
      <div
        className="curtomer-style"
        style={{ background: 'rgb(16, 34, 54)', height: '100%' }}
      >
        <Header
          style={{
            background: variables.menuBg,
            padding: 0,
            height: 'auto',
            width: '100%'
          }}
        >
          <Row>
            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
            <Col
              xs={16}
              sm={16}
              md={16}
              lg={16}
              xl={16}
              style={{ textAlign: 'center', color: '#ccc', fontSize: '20px' }}
            >
              {t('leftMenu.headerTitle')}
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={4}>
              <LangSelect />
            </Col>
          </Row>
        </Header>

        <div
          style={{
            border: '1px solid rgb(12,69,85)',
            padding: '0.2rem',
            width: '40%',
            margin: '0.2rem auto'
          }}
        >
          {/* 重置密码 */}
          <h1
            style={{
              textAlign: 'center',
              margin: '0.1rem auto',
              fontSize: '0.5rem',
              color: '#ccc'
            }}
          >
            {t('beforeLogin:resetPassword.resetPassword')}
          </h1>
          <Form
            id="normal_login"
            ref={this.resetPasswordForm}
            layout="vertical"
            onFinish={this.resetPassword}
            initialValues={{ newPwd: '' }}
          >
            <Row gutter={8}>
              {/* 新密码 */}
              <Form.Item
                label={t('beforeLogin:resetPassword.newPwd')}
                name="newPwd"
                style={{ width: '100%' }}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('beforeLogin:resetPassword.newPwdIsRequired')
                  },
                  {
                    validator: (rule, value) => {
                      if (typeof value === 'string') {
                        value = value.trim();
                      }
                      if (value.length >= 4 && value.length <= 20) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          t('beforeLogin:resetPassword.newPwdLimit')
                        );
                      }
                    }
                  }
                ]}
              >
                <Input type="password" maxLength={20} />
              </Form.Item>
            </Row>
            <Row gutter={8}>
              <Form.Item
                label={t('beforeLogin:resetPassword.confirmPwd')}
                name="confirmPwd"
                style={{ width: '100%' }}
                dependencies={['newPwd']}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('beforeLogin:resetPassword.confirmPwdRequired')
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (typeof value === 'string') {
                        value = value.trim();
                      }
                      if (!value || getFieldValue('newPwd') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t('beforeLogin:resetPassword.confirmPwdError')
                      );
                    }
                  })
                ]}
              >
                <Input type="password" maxLength={20} />
              </Form.Item>
            </Row>
            {/* 注册和取消按钮 */}
            <Row gutter={8}>
              <Form.Item style={{ margin: '4px auto' }}>
                <Button type="primary" htmlType="submit">
                  {' '}
                  {t('beforeLogin:resetPassword.submit')}
                </Button>
                <Button
                  htmlType="button"
                  style={{ marginLeft: '5px' }}
                  onClick={this.changeRoute}
                >
                  {' '}
                  {t('beforeLogin:resetPassword.cancel')}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.user.name
  };
};
export default withRouter(connect(mapStateToProps)(ResetPassword));
