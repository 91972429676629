import axios from 'axios';
import { getToken, removeToken, setToken } from '@/utils/token';
import loading from '@/utils/loading';
import history from './history';
import { message } from 'antd';
import i18n from '@/i18n/i18n.js';
import util from '@/utils/util';

const service = axios.create({
  baseURL: util.getPreAPI(), // api的base_url
  timeout: 50000, // request timeout
  isContinue: true // 是否继续请求接口
});

/* 在http请求发送给后端前，将请求参数中的字符串前后空格去掉 */
function requestDataRemoveSpaces(paramsObj, method) {
  for (let key in paramsObj) {
    if (typeof paramsObj[key] === 'string') {
      if (method === 'get') {
        if (
          paramsObj[key].trim().indexOf('[') > -1 ||
          paramsObj[key].trim().indexOf(']') > -1
        ) {
          paramsObj[key] = encodeURIComponent(paramsObj[key].trim());
        } else {
          paramsObj[key] = paramsObj[key].trim();
        }
      } else {
        paramsObj[key] = paramsObj[key].trim();
      }
    }
    if (
      typeof paramsObj[key] === 'object' &&
      paramsObj[key] !== null &&
      !(paramsObj[key] instanceof File)
    ) {
      requestDataRemoveSpaces(paramsObj[key], method);
    }
  }
}

// 请求拦截器
service.interceptors.request.use(
  config => {
    loading.show(config);
    let token = getToken();
    config.headers['language'] = util.convertLangage();
    if (token) {
      config.headers['System'] = 'CNI';
      config.headers['Authorization'] = 'Bearer ' + token; // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    requestDataRemoveSpaces(config.data || config.params, config.method);
    // 切换数据中心
    const dataCenter = localStorage.getItem('dataCenter');
    if (dataCenter) config.baseURL = dataCenter + config.baseURL;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    let currentLanguage = i18n.language; // 获取当前语言
    loading.hide(response.config);
    const res = response.data;
    message.destroy();
    if (
      response.config.responseType &&
      response.config.responseType === 'blob'
    ) {
      return response;
    } else if (response.status === 200 && res.code === 406) {
    } else if (response.status === 200 && res.code === 200) {
      return response.data;
    } else if (response.status === 200 && res.code !== 200) {
      let resCode = res.code;
      message.error(res.msg);
      if (resCode === 6069 || res.Status == 403) {
        window.location.href = '/login';
        removeToken();
        return;
      }

      return Promise.reject(res.code);
    }
  },
  error => {
    loading.hide(error.config);
    if (error.response && error.response.status === 401) {
      // 如果调用的接口是退出登录,则清除localStorage，跳转到登录页面
      // if (error.config.url.indexOf('logout') > -1) {
      removeToken();
      // localStorage.clear();
      history.push('/login');
      // } else {
      //   // 刷新Token接口
      //   reFreshToken().then(res => {
      //     if (res.code === 200) {
      //       setToken(res.extra.newToken);
      //       window.location.reload();
      //     }
      //   });
      // }
    } else if (error.response && error.response.status === 500) {
      message.error('服务器异常');
    } else if (error.response && error.response.status === 404) {
      message.error('请检查请求路径');
    } else if (error.response && error.response.status === 403) {
      message.error('无当前资源的访问权限');
    } else if (error.message && error.message.indexOf('timeout') > -1) {
      message.error('网络请求超时');
    } else {
      message.error('请检查网络连接状态');
      if (error?.config?.url?.indexOf('logout') > -1) {
        removeToken();
        localStorage.clear();
        history.push('/login');
      } else {
      }
    }
    return Promise.reject(error);
  }
);

export default service;
