/*
 * @Description:
 * @Author: April
 * @Date: 2022-11-24 14:40:34
 * @LastEditors: Summer.Chen
 * @LastEditTime: 2024-05-16 10:27:35
 */
import { langLeftMenu } from './locales/leftMenu';
import { langCommon } from './locales/common'; //公共需要翻译的内容
import { langPandect } from './locales/pandect'; //公共需要翻译的内容
import { langAuthManagement } from './locales/authManagement'; //公共需要翻译的内容
import { langRegister } from './locales/registerPage'; // 注册页翻译
import { langBeforeLogin } from './locales/beforeLoginTrans'; // 忘记用户名，忘记密码，重置密码页面翻译
import { langMainPage } from './locales/mainPageTrans'; // 系统主页面
import { langSystemList } from './locales/systemListTrans'; // 系统列表页
import { langEvent } from './locales/eventPageTrans'; // 事件页面翻译
import { langPwdMessage } from './locales/changePwd_MessageTrans';
import { langSysSettings } from './locales/systemSettingTrans'; // 系统设置翻译
import { langEnergyStorageData } from './locales/energyStorageDataTrans'; // 储能数据翻译
import { langBackStage } from './locales/backStageManageTrans'; // 后台管理翻译
import { langCodeValue } from './locales/codeValue'; // 后端返回的code值对应内容的翻译
import { langUpdate } from './locales/updateManagement';
import { langHistory } from './locales/historyData';
import { bms } from './locales/bms';
import { productSuggestions } from './locales/productSuggestions';
import { energyStorageSystemMaintenance } from './locales/energyStorageSystemMaintenance';
const resources = {
  en: {
    common: {
      ...langCommon.en_common
    },
    leftMenu: {
      ...langLeftMenu.en_leftMenu
    },
    pandect: {
      ...langPandect.en_pandect
    },
    authManagement: {
      ...langAuthManagement.en_authManagement
    },
    registerPage: {
      ...langRegister.en_registerPage
    },
    beforeLogin: {
      ...langBeforeLogin.en_beforeLogin
    },
    mainPage: {
      ...langMainPage.en_mainPage
    },
    systemList: {
      ...langSystemList.en_systemList
    },
    eventTrans: {
      ...langEvent.en_event
    },
    pwdMessage: {
      ...langPwdMessage.en_changePwd_Message
    },
    sysSettings: {
      ...langSysSettings.en_sysSettings
    },
    energyStorageData: {
      ...langEnergyStorageData.en_energyStorageData
    },
    backStageTrans: {
      ...langBackStage.en_backStage
    },
    backCodeTrans: {
      ...langCodeValue.en_codeValue
    },
    updateManagement: {
      ...langUpdate.en_update
    },
    historyData: {
      ...langHistory.en_history
    },
    bms: {
      ...bms.en_bms
    },
    productSuggestions: {
      ...productSuggestions.en_productSuggestions
    },
    energyStorageSystemMaintenance: {
      ...energyStorageSystemMaintenance.en_energyStorageSystemMaintenance
    }
  },
  zh: {
    common: {
      ...langCommon.zh_common
    },
    leftMenu: {
      ...langLeftMenu.zh_leftMenu
    },
    pandect: {
      ...langPandect.zh_pandect
    },
    authManagement: {
      ...langAuthManagement.zh_authManagement
    },
    registerPage: {
      ...langRegister.zh_registerPage
    },
    beforeLogin: {
      ...langBeforeLogin.zh_beforeLogin
    },
    mainPage: {
      ...langMainPage.zh_mainPage
    },
    systemList: {
      ...langSystemList.zh_systemList
    },
    eventTrans: {
      ...langEvent.zh_event
    },
    pwdMessage: {
      ...langPwdMessage.zh_changePwd_Message
    },
    sysSettings: {
      ...langSysSettings.zh_sysSettings
    },
    energyStorageData: {
      ...langEnergyStorageData.zh_energyStorageData
    },
    backStageTrans: {
      ...langBackStage.zh_backStage
    },
    backCodeTrans: {
      ...langCodeValue.zh_codeValue
    },
    updateManagement: {
      ...langUpdate.zh_update
    },
    historyData: {
      ...langHistory.zh_history
    },
    bms: {
      ...bms.zh_bms
    },
    productSuggestions: {
      ...productSuggestions.zh_productSuggestions
    },
    energyStorageSystemMaintenance: {
      ...energyStorageSystemMaintenance.zh_energyStorageSystemMaintenance
    }
  },
  de: {
    common: {
      ...langCommon.de_common
    },
    leftMenu: {
      ...langLeftMenu.de_leftMenu
    },
    pandect: {
      ...langPandect.de_pandect
    },
    authManagement: {
      ...langAuthManagement.de_authManagement
    },
    registerPage: {
      ...langRegister.de_registerPage
    },
    beforeLogin: {
      ...langBeforeLogin.de_beforeLogin
    },
    mainPage: {
      ...langMainPage.de_mainPage
    },
    systemList: {
      ...langSystemList.de_systemList
    },
    eventTrans: {
      ...langEvent.de_event
    },
    pwdMessage: {
      ...langPwdMessage.de_changePwd_Message
    },
    sysSettings: {
      ...langSysSettings.de_sysSettings
    },
    energyStorageData: {
      ...langEnergyStorageData.de_energyStorageData
    },
    backStageTrans: {
      ...langBackStage.de_backStage
    },
    backCodeTrans: {
      ...langCodeValue.de_codeValue
    },
    updateManagement: {
      ...langUpdate.de_update
    },
    historyData: {
      ...langHistory.de_history
    },
    bms: {
      ...bms.de_bms
    },
    productSuggestions: {
      ...productSuggestions.de_productSuggestions
    },
    energyStorageSystemMaintenance: {
      ...energyStorageSystemMaintenance.de_energyStorageSystemMaintenance
    }
  }
};
export { resources };
