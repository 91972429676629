/*
 * @Description:
 * @Author: XuAnjie
 * @Date: 2022-10-13 17:12:29
 * @LastEditors: Wayne Hu
 * @LastEditTime: 2023/11/30 17:18:05
 */
import React from 'react';
import { Layout } from 'antd';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SiderMenu from './SiderMenu';
import Icon, { AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';

const { Sider } = Layout;
class AlphaESSSider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      depotId: ''
    };
  }
  render() {
    let self = this;
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth={this.props.responsive ? 0 : undefined}
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        width="248"
      >
        {!this.props.responsive ? (
          <>
            <Icon
              style={{
                color: 'var(--collapsed-icon-color)',
                fontSize: '0.3rem',
                padding: ' 10px 10px 10px 24px',
                cursor: 'pointer'
              }}
              onClick={() => {
                self.props.toggle();
              }}
              component={
                self.props.collapsed ? AlignRightOutlined : AlignLeftOutlined
              }
            />
            <img
              id={
                this.props.theme !== 'dark' && this.props.language !== 'zh'
                  ? 'light-en'
                  : ''
              }
              className={
                this.props.language === 'zh' ? 'facion_ZH' : 'facion_EN'
              }
              src={
                this.props.language === 'zh'
                  ? this.props.theme === 'dark'
                    ? '/static/svg/dark/dark-zh-logo.svg'
                    : '/static/svg/logo-light.svg'
                  : this.props.theme === 'dark'
                    ? '/static/svg/dark/dark-en-logo.svg'
                    : '/static/svg/logo_light_en.svg'
              }
            />
          </>
        ) : null}

        {this.props.menus.length ? (
          <SiderMenu
            className={
              this.props.language === 'zh'
                ? this.props.theme === 'dark'
                  ? 'sider-menu-dark side-menu-zh'
                  : 'sider-menu side-menu-zh'
                : this.props.theme === 'dark'
                  ? 'sider-menu-dark side-menu-en'
                  : 'sider-menu side-menu-en'
            }
            menus={this.props.menus}
            mode="inline"
            collapsed={1}
            onOpenChange={this.props.openMenu}
            selectedKeys={[this.props.selectedKey]}
            openKeys={this.props.openKeys}
            stationType={this.props.stationType}
          />
        ) : null}
      </Sider>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.user.name,
    avatar: state.user.avatar,
    username: state.user.username,
    depotId: state.app.depotId,
    projectName: state.app.projectName,
    language: state.app.language,
    theme: state.app.theme,
    stationType: state.app.stationType // 系统类型  工商业 or BMS
  };
};
export default withRouter(connect(mapStateToProps)(AlphaESSSider));
