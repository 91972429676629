import React from 'react';
import { withTranslation } from 'react-i18next'; // 国际化
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import '@/style/login.less';
import beforeLogin from '@/services/beforeLogin.js';
import LangSelect from '@/components/LangSelect';
import { theme } from '@/theme';

@withTranslation(['leftMenu', 'beforeLogin', 'authManagement'])

// 忘记密码页面
class ForgetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.forgetPasswordForm = React.createRef(); // 忘记密码表单
    this.state = {
      time: 60 // 规定起始时间60s
    };
  }
  componentDidMount() {
    this.curtomerHeight();
    let model = 'light';
    window.less.modifyVars({
      ...theme[model]
    });
  }
  curtomerHeight() {
    document.querySelector('.curtomer-style-register').style.height =
      window.innerHeight;
  }
  // 下一步
  submitForgetPassword = values => {
    const { t } = this.props;
    // delete values.confirmPassword;
    // 表单字段前后去空格
    for (let key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    beforeLogin
      .confirmForgetPasswordCode(values)
      .then(res => {
        if (res && res.code === 200) {
          this.props.history.push(
            `/NewResetPassword?userId=${res.data.userId}&hour=${res.data.hour}`
          );
        }
      })
      .catch(error => {});
  };
  // 注册发送邮件
  sendRegisterEmail = callback => {
    const { t } = this.props;
    const username = this.forgetPasswordForm.current.getFieldValue('userName');
    localStorage.setItem('username', username);
    beforeLogin.sendResetPasswordEmail({ userName: username }).then(ret => {
      if (ret && ret.code === 200) {
        message.success(t('registerPage:registerPage.verificationCodeSuccess'));
        if (typeof callback === 'function') {
          callback();
        }
      }
    });
  };
  // 点击验证码触发计时器
  getCode = event => {
    this.forgetPasswordForm.current
      .validateFields(['username'])
      .then(values => {
        this.sendRegisterEmail(() => {
          setTimeout(() => this.onGetCode(), 0);
        });
      });
  };
  onGetCode = () => {
    let { time } = this.state;
    if (time > 0) {
      --time;
      this.setState({
        time
      });
      setTimeout(() => this.onGetCode(), 1000); //一秒执行后如果时间没到会再执行一次
    } else {
      this.setState({
        time: 60
      });
    }
  };
  // 跳转至重置密码页面
  // changeRoute = () => {
  //   this.props.history.push({
  //     pathname:'/NewResetPassword',
  //     state: {
  //     hour: rep.data.hour,
  //     userId: rep.data.userId,
  //     userName: values.userName,
  //   }});
  // };
  // 组件挂载前
  UNSAFE_componentWillMount() {
    document.getElementsByTagName('body')[0].style.background =
      "url('/static/images/login_bg.png') center center / cover no-repeat";
  }
  render() {
    let { t } = this.props;
    return (
      <div className="curtomer-style-register register_mainBox">
        <div className="forget_main">
          {/*公司logo */}
          <div className="logo flex">
            <span className="logo-content">
              <img alt="logo" src="/static/images/alpha-logo.png" />
            </span>
            <div className="switch-language">
              <LangSelect />
            </div>
          </div>
          <div className="forget_form_body">
            <div className="register_login">
              <span className="register_text_Account">
                {t('beforeLogin:forgetPassword.AlreadyHaveAnAccount')}{' '}
              </span>
              <a className="register_text_login" href="/#/Login">
                {t('beforeLogin:forgetPassword.LogInNow')}
              </a>
            </div>
            <div className="register_bg">
              {/* 忘记密码 */}
              <h1 className="register_title">
                {t('beforeLogin:forgetPassword.forgetPassword')}
              </h1>
              <Form
                className="forget_form"
                ref={this.forgetPasswordForm}
                id="normal_login"
                layout="vertical"
                onFinish={this.submitForgetPassword}
                initialValues={{
                  userName: '',
                  code: '',
                  password: '',
                  confirmPassword: ''
                }}
              >
                <Row gutter={8}>
                  {/* 用户名 */}
                  <Form.Item
                    label={t('registerPage:registerPage.userName')}
                    name="userName"
                    className="forget_hundred"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: t('registerPage:registerPage.userNameRequired')
                      },
                      {
                        pattern:
                          /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z0-9_\-\+]{2,8})$/,
                        message: t('registerPage:registerPage.userFormatLimit')
                      }
                    ]}
                  >
                    <Input maxLength={64} />
                  </Form.Item>
                </Row>
                <Row gutter={8}>
                  {/* 验证码 */}
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={t('registerPage:registerPage.verificationCode')}
                      name="code"
                      validateTrigger="onBlur"
                      className="forget_hundred"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'registerPage:registerPage.verificationCodeRequired'
                          )
                        }
                      ]}
                    >
                      <Input maxLength={8} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label=" ">
                      {this.state.time === 60 || this.state.time === 0 ? (
                        <Button
                          type="primary"
                          size="size"
                          shape="round"
                          className="btn-radius_25 btn_white"
                          onClick={this.getCode}
                        >
                          {t('registerPage:registerPage.getVerificationCode')}
                        </Button>
                      ) : (
                        <Button size="size" shape="round" disabled={true}>
                          {this.state.time + ' s'}
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                {/* 注册和取消按钮 */}
                <Row gutter={8}>
                  <Form.Item className="register_box">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="forget_btn btn-radius_25 btn_white"
                    >
                      {t('authManagement:authManagement.nextStep')}
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.user.name
  };
};
export default withRouter(connect(mapStateToProps)(ForgetPassword));
