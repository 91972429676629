/*
 * @Description:
 * @Author: April
 * @Date: 2023-07-03 15:20:02
 * @LastEditors: Summer.Chen
 * @LastEditTime: 2024-05-24 13:56:21
 */
import CryptoJS from 'crypto-js/crypto-js';
import { message } from 'antd';

// 下载或导出
export function blobDownloadFile(rep, fileName) {
  let name = '';
  let regex = /"([^"]*)"/g;
  let currentResult;
  let results = [];
  let strValue = '';
  const str = rep.headers['content-disposition'];
  if (fileName) {
    name = fileName;
  } else {
    if (str) {
      name = str.split(';')[1].substring(str.indexOf('filename=') - 1);
      name = name.replace(new RegExp('"', 'g'), '');
      name = decodeURIComponent(name);
    }
  }
  if (str) {
    strValue = str.split(';')[1];
    while ((currentResult = regex.exec(strValue)) !== null) {
      results.push(currentResult[1]);
    }
  }

  let fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result);
      if (jsonData.msg) {
        message.error(jsonData.msg);
      }
    } catch (error) {
      let fileDownload = require('js-file-download');
      fileDownload(rep.data, name);
    }
  };
  fileReader.readAsText(rep.data);
}
/* 密码加密*/
export function encrypt(password, username) {
  let aeskey = CryptoJS.SHA256(username);
  let iv = CryptoJS.MD5(username);
  let encrypted = CryptoJS.AES.encrypt(password, aeskey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

/* 密码解密*/
export function decrypt(password, username) {
  let aeskey = CryptoJS.SHA256(username);
  let iv = CryptoJS.MD5(username);
  let encrypted = CryptoJS.AES.decrypt(password, aeskey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = encrypted.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}
/* 获取簇、包数据源 */
export function getCList(count, text) {
  const arr = [];
  for (let index = 1; index < count + 1; index++) {
    if (index < 10) {
      arr.push({
        label: text + `0${index}`,
        value: index
      });
    } else {
      arr.push({
        label: text + `${index}`,
        value: index
      });
    }
  }
  return arr;
}
export const downUrl = 'https://alphatest.blob.core.windows.net';
