/*
 * @Description:
 * @Author: Summer.Chen
 * @Date: 2022-10-17 14:21:10
 * @LastEditTime: 2024/11/05 17:28:47
 * @LastEditors: Please set LastEditors
 */
// app action types
export const app = {
  SPIN_LOADING: 'SPIN_LOADING',
  UPDATE_TOPATH: 'UPDATE_TOPATH',
  UPDATE_ACCESSMENU: 'UPDATE_ACCESSMENU',
  UPDATE_MODULE: 'UPDATE_MODULE',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  UPDATE_SYS_SN: 'UPDATE_SYS_SN',
  UPDATE_SYS_LIST: 'UPDATE_SYS_LIST',
  UPDATE_DEPOT_ID: 'UPDATE_DEPOT_ID',
  UPDATE_SYS_ROLE: 'UPDATE_SYS_ROLE',
  SET_PROJECT_NAME: 'SET_PROJECT_NAME',
  UPDATE_THEME: 'UPDATE_THEME',
  DATA_TYPE: 'DATA_TYPE',
  UPDATE_BLMUCOUNT: 'UPDATE_BLMUCOUNT',
  UPDATE_BCMUCOUNT: 'UPDATE_BCMUCOUNT',
  UPDATE_BLMUINFO: 'UPDATE_BLMUINFO',
  UPDATE_STATION_TYPE: 'UPDATE_STATION_TYPE',
  UPDATE_SHOW_CHANGE_STATION: 'UPDATE_SHOW_CHANGE_STATION'
};
// user action types
export const user = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  GET_USERINFO_SUCCESS: 'GET_USERINFO_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS'
};
