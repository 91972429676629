/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:21:59
 * @LastEditTime: 2023/12/25 19:09:52
 */
import en_authManagement from './en-US/translation.json';
import zh_authManagement from './zh-CN/translation.json';
import de_authManagement from './de-DE/translation.json';

export const langAuthManagement = {
  en_authManagement,
  zh_authManagement,
  de_authManagement
};
