const TokenKey = 'AlphaESSToken';
const leftMenuKey = 'AlphaESSLeftMenu';
const userInfoKey = 'userInfo';
export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  localStorage.removeItem(TokenKey);
}
export function getLeftMenuList() {
  return localStorage.getItem(leftMenuKey);
}
export function setLeftMenuList(leftMenu) {
  localStorage.setItem(leftMenuKey, leftMenu);
}
export function removeLeftMenuList() {
  localStorage.removeItem(leftMenuKey);
}
export function getUserInformation() {
  return localStorage.getItem(userInfoKey);
}
export function setUserInformation(userInfo) {
  localStorage.setItem(userInfoKey, userInfo);
}
export function removeUserInformation() {
  localStorage.removeItem(userInfoKey);
}
