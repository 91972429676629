/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:14:10
 */
import en_pandect from './en-US/translation.json';
import zh_pandect from './zh-CN/translation.json';
import de_pandect from './de-DE/translation.json';

export const langPandect = { en_pandect, zh_pandect, de_pandect };
