/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:15:03
 */
import zh_sysSettings from './zh-CN/translation.json';
import en_sysSettings from './en-US/translation.json';
import de_sysSettings from './de-DE/translation.json';

export const langSysSettings = {
  zh_sysSettings,
  en_sysSettings,
  de_sysSettings
};
