/*
 * @Description:
 * @Author: Wayne Hu
 * @LastEditors: Wayne Hu
 * @Date: 2023/12/25 14:22:00
 * @LastEditTime: 2023/12/25 19:12:27
 */
import en_common from './en-US/translation.json';
import zh_common from './zh-CN/translation.json';
import de_common from './de-DE/translation.json';

export const langCommon = { en_common, zh_common, de_common };
